<template>
  <MapBoxPopup
    v-if="chargingpoint"
    :coordinates="[coordinates.lng, coordinates.lat]"
    :offset="[0,-30]"
    :show="show"
    @close="handleClose"
  >
    <div>
      <div class="d-flex flex-column">
        <div v-if="address">
          {{ address.simple_address }}
          <ButtonCopyValue :value="address.formatted_address || ''" />
          <ButtonFlyTo :coordinates="[coordinates.lng, coordinates.lat]" />
        </div>
        <div>
          {{ $t(`participationPanel.options.statusStations.regularLabels.${statusLabel}`) }}
        </div>
        <div>
          CPO: {{ cpo ? cpo.name : $t('none') }}
        </div>
        <div>
          ID: {{ `${code}-${id}` }}
          <img
            :src="statusIcon"
            class="statusIcon"
            width="15"
            alt=""
          >
        </div>
        <p v-if="prio && prio.order">
          Prioriteit: {{ prio.order }} {{ prio.fase ? `(Fase ${prio.fase})` : '' }}
        </p>
        <div v-if="introducedBy">
          <span class="text-muted">{{ introducedBy }}</span>
        </div>
        <ValidationCounter class="mt-1" />
        <div
          v-if="remark"
          class="mt-3"
        >
          <strong>{{ $t('remark') }}</strong> <br>
          {{ remark | truncate }}
        </div>
      </div>

      <div
        v-if="features.length > 1"
      >
        <hr class="mt-3 mb-2">

        <b-pagination
          v-model="currentPage"
          class="justify-content-center pagination-sm m-0"

          hide-goto-end-buttons
          :total-rows="features.length"
          :per-page="1"
        />
      </div>
    </div>
  </MapBoxPopup>
</template>

<script>

import MapBoxPopup from '@/components/common/MapBoxPopup'

import { mapActions, mapGetters } from 'vuex'

import { EventBus } from '@/services/eventbus'
import ValidationCounter from '@/components/map/sidebar/ValidationCounter'
import { STAKEHOLDER_TYPE } from '@/../shared/valueholders/stakeholder-types'
import chargingpointDataMixin from '@/mixins/chargingpoint/chargingpointDataMixin'
import ButtonCopyValue from '@/components/common/ButtonCopyValue'
import ButtonFlyTo from '@/components/common/ButtonFlyTo.vue'
import { image } from '@/helpers/assets'

export default {
  name: 'ChargerDetailsPopup',
  components: { ButtonFlyTo, ValidationCounter, MapBoxPopup, ButtonCopyValue },
  filters: {
    truncate(value) {
      if (value.length > 128) {
        return value.substring(0, 128) + '...'
      }

      return value
    },
  },
  mixins: [chargingpointDataMixin],
  data() {
    return {
      show: false,
      doNotDeselectChargingpoint: false,
      currentPage: 1,
      features: [],
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('planmode', [
      'getChargingPointByRefId',
      'getChargingPointByUuid',
      'getPlanmodeLayers',
      'isChargingpointSelected',
    ]),
    statusLabel() {
      return this.status
    },
    cpo() {
      return this.stakeholders.find(stakeholder => stakeholder.type === STAKEHOLDER_TYPE.CPO)
    },
    visibleChargingPoints() {
      return [this.features[this.currentPage - 1] || false]
    },
    statusIcon: function () {
      return image({
        name: `chargingpoint/png/${this.chargingpoint.data.properties.status}.png`,
      })
    },
  },
  watch: {
    /**
     * Hide the popup whenever the municiaplity changes
     */
    getActiveMunicipality() {
      this.show = false
    },
    currentPage() {
      this.handleOpen({ refId: this.visibleChargingPoints[0]?.properties.refId })
    },
  },
  /**
   * Bind event handlers to MapBox
   */
  created() {
    this.$store.map.on('click', 'chargingpoints', this.handleClickMarkerEvent)

    EventBus.$on('select-chargingpoint', this.handleSelectChargingpointEvent)
    EventBus.$on('deselect-chargingpoint', this.handleClose)

    // Cursor
    this.$store.map.on('mouseenter', 'chargingpoints', this.showPointer)
    this.$store.map.on('mouseleave', 'chargingpoints', this.hidePointer)
  },
  /**
   * Disconnect the event handlers from Mapbox
   */
  beforeDestroy() {
    this.$store.map.off('click', 'chargingpoints', this.handleClickMarkerEvent)

    EventBus.$off('select-chargingpoint', this.handleSelectChargingpointEvent)
    EventBus.$off('deselect-chargingpoint', this.handleClose)

    // Cursor
    this.$store.map.off('mouseenter', 'chargingpoints', this.showPointer)
    this.$store.map.off('mouseleave', 'chargingpoints', this.hidePointer)
  },
  methods: {
    ...mapActions('planmode', [
      'selectChargingPoint',
      'deselectChargingPoint',
    ]),

    /**
     * Mouse hover effects
     */
    showPointer() {
      this.$store.map.getCanvas().style.cursor = 'pointer'
    },
    hidePointer() {
      this.$store.map.getCanvas().style.cursor = ''
    },
    /**
     * Load the outside selected point's properties
     */
    handleSelectChargingpointEvent(refId) {
      let chargingpoint = this.getChargingPointByRefId({ refId })

      this.handleOpen({ chargingpoint })
    },
    /**
     * Load the clicked point's properties
     */
    handleClickMarkerEvent(e) {
      if (!e.features.length) return

      this.features = e.features.filter(feature => feature.layer.paint['icon-opacity'] !== 0)

      // if layer is not visible don't react to clicks
      if (!this.features.length) return

      // Cancel other map events
      e.preventDefault()
      e.originalEvent.stopPropagation()

      // DEBUGGING mapbox features:
      // console.log('event:: ',e.features[0])

      // Get the charging point to get the exact coordinates & user details
      const uuid = this.features[0].properties.uuid
      const chargingpoint = this.getChargingPointByUuid({ uuid })

      // if there is already a popup opened, set a flag to not deselect the chargingpoint in the store.
      // This is to avoid a flickering of the sidebar, since we need to wait for the nextTick and the current popup is closed

      if (this.isChargingpointSelected && this.show) {
        this.doNotDeselectChargingpoint = true
      }

      this.show = false

      this.$nextTick(() => {
        this.handleOpen({ chargingpoint })

        this.doNotDeselectChargingpoint = false
      })
    },

    /**
     * Upon opening, either by click or event
     */
    handleOpen({ chargingpoint, refId }) {
      if (chargingpoint) {
        this.selectChargingPoint({ refId: chargingpoint.ref['@ref'].id })
      } else if (refId) {
        this.selectChargingPoint({ refId })
      }

      this.show = true
    },

    /**
     * Upon the close event of the popup
     */
    handleClose() {
      this.show = false

      // if this flag is set, we don't want to deselect the chargingpoint in the store to avoid a flickr,
      // while switching between chargingpoints on the map
      if (this.doNotDeselectChargingpoint === false) {
        this.deselectChargingPoint()
      }
    },
  },
}
</script>

<style lang="scss">
.mapboxgl-popup-content {
  padding: 10px 20px 10px 10px !important;

  p {
    margin: 0;
    user-select: auto;
  }

  .statusIcon {
    margin-top: -3px;
  }
}
</style>
