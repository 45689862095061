/**
 * Export format to support Node.js scripts
 */
module.exports = {
  deprecatedCodesByDeployment: {
    nl: [],
    be: [],
  },
  regionsByDeployment: {
    nl: [
      {
        slug: 'mrae',
        label: 'Regio MRAe',
        proxy: [
          '0171', '0184', '0303', '0034', '0050', '0995', '1598', '1696', '1911', '1942', '0358', '0361', '0362', '0373', '0375', '0376', '0377', '0383', '0384', '0385', '0388', '0392', '0394', '0396', '0397', '0399', '0400', '0402', '0405', '0406', '0415', '1980', '0417', '0420', '0431', '0432', '0437', '0439', '0441', '0448', '0450', '0451', '0453', '0473', '0479', '0498', '0532', '0852', '0880', '1581', '1904', '1961', '0307', '0308', '0310', '0312', '0313', '0317', '0321', '0327', '0331', '0335', '0339', '0340', '0342', '0345', '0351', '0352', '0353', '0355', '0356', '0589', '0632', '0736', '9005', '9010', '9011',
        ],
      }, {
        slug: 'have-provincial-grondeigendom',
        geographies: [{
          type: 'province',
          code: 24,
        }, {
          type: 'province',
          code: 26,
        }, {
          type: 'province',
          code: 27,
        }],
      }, {
        slug: 'west-nederland',
        geographies: [{
          type: 'province',
          code: 26,
        }, {
          type: 'province',
          code: 27,
        }, {
          type: 'province',
          code: 28,
        }, {
          type: 'province',
          code: 29,
        }],
      },
    ],
    be: [],
  },
}
