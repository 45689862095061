<template>
  <div class="ActiveTab ProcessInfoTab">
    <LocatieInformatieHeader :chargingpoint="chargingpoint">
      <h4>{{ $t('components.sidebar.Tabs.ProcessInfoTab.title') }}</h4>
    </LocatieInformatieHeader>

    <div class="ActiveTab__Content">
      <div v-if="isInProcess">
        <p>
          <strong>{{ $t('components.sidebar.Tabs.ProcessInfoTab.caseRef') }}</strong><br>
          <span> {{ caseRef }}</span>
        </p>
      </div>
      <div v-else>
        <span> {{ $t('components.sidebar.Tabs.ProcessInfoTab.notLinked') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import chargingpointDataMixin from '@/mixins/chargingpoint/chargingpointDataMixin'
import LocatieInformatieHeader from '@/components/map/sidebar/LocatieInformatieHeader'

export default {
  components: { LocatieInformatieHeader },
  mixins: [chargingpointDataMixin],
  computed: {
    isInProcess() {
      return (
        !!this.chargingpoint.data?.workflowCaseRef ||
        !!this.chargingpoint.data?.externalProcessId
      )
    },
    caseRef() {
      return (
        this.chargingpoint.data?.workflowCaseRef ??
        this.chargingpoint.data?.externalProcessId
      )
    },
  },
}
</script>
