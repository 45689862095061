<template>
  <div class="d-flex flex-column p-4">
    <div class="SuperuserUploadPanel d-flex mt-3 pb-3">
      <aside class="SuperuserUploadPanel__Aside flex-shrink-0 mr-3">
        <h3 class="SuperuserUploadPanel__Title">
          {{ $t('components.upload.SuperUserUploadPanel.explanation') }}
        </h3>
        <div class="w-75 mt-3">
          <p>
            {{ $t('components.upload.SuperUserUploadPanel.step1') }}
          </p>
          <p>
            {{ $t('components.upload.SuperUserUploadPanel.step2') }}
          </p>
          <p>
            {{ $t('components.upload.SuperUserUploadPanel.step3') }}
          </p>
        </div>

        <b-button
          :disabled="!enableUpload"
          variant="cta"
          class="mt-2"
          @click="$_chargingpointAttachmentsMixin_bulkUploadAttachments"
        >
          {{ $t("components.upload.UploadPanel.upload") }}
        </b-button>

        <b-button
          class="mt-2 ml-2"
          variant="outline-cta"
          @click="resetItems"
        >
          {{ $t('components.upload.SuperUserUploadPanel.reset') }}
        </b-button>
      </aside>

      <div
        class="SuperuserUploadPanel__Main flex-grow-1 flex-shrink-1 mb-3"
        @dragenter.stop.prevent="isDragging = true"
        @dragover.stop.prevent="() => {}"
        @dragleave.stop.prevent="isDragging = false"
      >
        <div
          v-if="isDragging || bulkUploadItems.length === 0"
          class="dropzone"
          :class="{ 'is-dragging': isDragging }"
          @click="() => $refs['file-input'].click()"
          @drop.stop.prevent="$_chargingpointAttachmentsMixin_handleDrop($event, ATTACHMENT_ID.BULK)"
        >
          {{ $t('dragNselect') }}
        </div>

        <div
          v-else
          class="s3-bulk-upload"
        >
          <h3>
            {{ $t('components.upload.SuperUserUploadPanel.totalUploads') }} {{ uploadedCount }} / {{ bulkUploadItems.length }}
          </h3>

          <div class="pl-4 mt-3 d-flex position-relative">
            <b-spinner
              v-if="isLoadingAws"
              class="s3-bulk-upload-busy"
              small
            />
            <b-form-checkbox v-model="all">
              {{ $t('components.upload.SuperUserUploadPanel.allItemsCheck') }}
            </b-form-checkbox>
          </div>

          <hr class="divider mt-2">

          <template v-if="bulkUploadFails.length > 0">
            <Feedback
              v-for="fail in bulkUploadFails"
              :key="fail.file.name + '-fail'"
              class="mb-3 font-size-small"
              :dismissible="true"
              :feedback="{variant: 'warning'}"
            >
              {{ fail.file.name }} {{ $t('components.upload.SuperUserUploadPanel.uploadFail') }}
            </Feedback>
          </template>

          <div
            v-for="item in bulkUploadItems"
            :key="item.file.name + (item.uploaded ? 'is-uploaded' : 'is-not-uploaded')"
            class="pl-4 s3-bulk-upload-item"
          >
            <SvgIcon
              v-if="item.uploaded"
              class="s3-bulk-upload-item-done"
              icon="check-circle-regular"
            />
            <SvgIcon
              v-else-if="item.error"
              class="s3-bulk-upload-item-error"
              icon="exclamation-circle-regular"
            />
            <b-form-checkbox
              v-model="item.edited"
              class="s3-bulk-upload-item-label"
            >
              {{ item.file.name }}<span v-if="item.edited"> {{ $t('components.upload.SuperUserUploadPanel.itemCheck') }}</span>
              <b-button
                size="sm"
                variant="light"
                class="s3-bulk-upload-item-delete ml-3"
                title="Verwijderen"
                @click="$_chargingpointAttachmentsMixin_handleRemoveItemFromBulk"
              >
                x
              </b-button>
            </b-form-checkbox>
          </div>
        </div>

        <input
          ref="file-input"
          type="file"
          hidden
          multiple
          @change="$_chargingpointAttachmentsMixin_handleFileChange($event, ATTACHMENT_ID.BULK)"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { ATTACHMENT_ID } from '@/../shared/valueholders/attachments'

import chargingpointAttachmentsMixin from '@/mixins/chargingpoint/chargingpointAttachmentsMixin'
import chargingpointsLoadMixin from '@/mixins/chargingpoint/chargingpointsLoadMixin'
import SvgIcon from '@/components/common/SvgIcon'
import Feedback from '@/components/form/Feedback'

const MAX_UPLOADS = 999

export default {
  components: { Feedback, SvgIcon },
  mixins: [chargingpointAttachmentsMixin, chargingpointsLoadMixin],
  data() {
    return {
      selected: [],
      isDragging: false,

      busy: false,
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    itemsLoaded() {
      return !this.isLoadingFauna && this.items.length > 0
    },
    hasFiles() {
      return this.bulkUploadItems.length > 0
    },
    enableUpload() {
      return ! this.isLoadingAws && this.hasFiles && this.bulkUploadItems.length < MAX_UPLOADS
    },
    uploadedCount() {
      return this.bulkUploadItems.filter(item => item.uploaded !== false).length
    },

    /**
     * Watch the all items on / off toggle.
     */
    all: {
      get() {
        return ! this.bulkUploadItems.some(item => !item.edited)
      },
      set(value) {
        // fixes a buggy behaviour that the first new value
        // for a single item triggers the all setter again
        if (value !== this.all) {
          this.bulkUploadItems.forEach(item => item.edited = value)
        }
      },
    },
  },
  watch: {
    getActiveMunicipality() {
      this.init()
    },
  },
  created() {
    this.init()
    this.ATTACHMENT_ID = ATTACHMENT_ID
  },
  methods: {
    async init() {
      await this.$_chargingpointsLoadMixin_loadChargingPoints({
        code: this.getActiveMunicipality,
      })
    },
    resetItems() {
      this.bulkUploadItems = []
      this.bulkUploadFails = []
    },
  },
}
</script>

<style lang="scss" scoped>


.SuperuserUploadPanel {
  &__Aside {
    width: 275px;
  }

  &__Main {
    font-size: 1.25rem;

    .form-control {
      width: 100px;
      font-size: 1.25rem;
    }
  }

  &__Label {
    width: 175px;
  }

  &__Input {
    position: relative;
    width: 360px;

    &:after {
      content: attr(data-intructions);
      position: absolute;
      top: 3.1rem;
      font-size: 1rem;
      left: 0;
      color: #7F8FA4;

    }
  }

  &__Download {
    text-decoration: underline;
    color: var(--cta);
  }

  .SvgIcon {
    flex-shrink: 0;
    font-size: 1.75rem;
  }

  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: 'Selecteer';
  }

  .s3-bulk-upload {
    &-busy {
      color: var(--primary);
      font-size: 15px;
      position: absolute;
      left: 0;
      top: 4px;
    }

    &-item {
      display: flex;
      position: relative;

      &-done, &-error {
        font-size: 15px;
        position: absolute;
        left: 0;
        top: 2px;
      }

      &-done {
        color: var(--success);
      }

      &-error {
        color: var(--danger);
      }

      &-label {
        cursor: pointer;
      }

      &-delete {
        cursor: pointer;
      }
    }
  }

  .font-size-small {
    font-size: 14px;
  }

  .dropzone {
    cursor: pointer;
    padding: 1.5rem;
    border: 3px solid var(--grey);
    border-radius: 5px;
  }

  .is-dragging {
    animation-duration: .3s;
    animation-name: wobbel;
    animation-iteration-count: infinite;
    transition-timing-function: ease-out;
  }

  @keyframes wobbel {
    0% { transform: rotate(0); }

    25% { transform: rotate(-2deg); }

    50% { transform: rotate(0); }

    75% { transform: rotate(2deg); }

    100% { transform: rotate(0); }
  }
}
</style>
