<template>
  <div class="InlineChargingpoint">
    <img
      class="d-inline mr-1"
      width="15"
      :src="icon"
    >
    {{ `${id}: ${address || remark || statusLabel}` }}
    <slot />
  </div>
</template>

<script>
import { image } from '@/helpers/assets'

export default {
  name: 'InlineChargingpoint',
  props: {
    chargingpoint: {
      type: Object,
      required: true,
    },
  },
  computed: {
    id() {
      return this.chargingpoint.data?.properties.id || this.chargingpoint.id || ''
    },
    status() {
      return this.chargingpoint.data?.properties.status || this.chargingpoint.status || ''
    },
    address() {
      return this.chargingpoint.data?.address.simple_address || this.chargingpoint.address || ''
    },
    remark() {
      return this.chargingpoint.data?.properties.remark || ''
    },
    statusLabel() {
      return this.$i18n. $t(`participationPanel.options.statusStations.regularLabels.${this.status}`)
    },
    icon() {
      return image({ name: `chargingpoint/png/${this.status}.png` })
    },
  },
}
</script>

<style lang="scss">
.InlineChargingpoint {
  position: relative;
}
</style>
