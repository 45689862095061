export const evPerChargingPoint = {
  'nl': {
    'public': {
      2020: {
        inhabitants: 2.5,
        commuters: 2.0,
        visitors: 10,
      },
      2021: {
        inhabitants: 2.8,
        commuters: 2.4,
        visitors: 12,
      },
      2022: {
        inhabitants: 3.1,
        commuters: 2.8,
        visitors: 14,
      },
      2023: {
        inhabitants: 3.4,
        commuters: 3.2,
        visitors: 16,
      },
      2024: {
        inhabitants: 3.7,
        commuters: 3.6,
        visitors: 18,
      },
      2025: {
        inhabitants: 4.0,
        commuters: 4.0,
        visitors: 20,
      },
      2026: {
        inhabitants: 4.0,
        commuters: 4.0,
        visitors: 20,
      },
      2027: {
        inhabitants: 4.0,
        commuters: 4.0,
        visitors: 20,
      },
      2028: {
        inhabitants: 4.0,
        commuters: 4.0,
        visitors: 20,
      },
      2029: {
        inhabitants: 4.0,
        commuters: 4.0,
        visitors: 20,
      },
      2030: {
        inhabitants: 4.0,
        commuters: 4.0,
        visitors: 20,
      },
    },
    'private': {
      2020: {
        inhabitants: 1,
        commuters: 2.0,
        visitors: 5,
      },
      2021: {
        inhabitants: 1,
        commuters: 2.4,
        visitors: 6,
      },
      2022: {
        inhabitants: 1,
        commuters: 2.8,
        visitors: 7,
      },
      2023: {
        inhabitants: 1,
        commuters: 3.2,
        visitors: 8,
      },
      2024: {
        inhabitants: 1,
        commuters: 3.6,
        visitors: 9,
      },
      2025: {
        inhabitants: 1,
        commuters: 4.0,
        visitors: 10,
      },
      2026: {
        inhabitants: 1,
        commuters: 4.0,
        visitors: 10,
      },
      2027: {
        inhabitants: 1,
        commuters: 4.0,
        visitors: 10,
      },
      2028: {
        inhabitants: 1,
        commuters: 4.0,
        visitors: 10,
      },
      2029: {
        inhabitants: 1,
        commuters: 4.0,
        visitors: 10,
      },
      2030: {
        inhabitants: 1,
        commuters: 4.0,
        visitors: 10,
      },
    },
  },
  'be': {
    'public': {
      2020: {
        inhabitants: 2.5,
        commuters: 2.0,
        visitors: 10,
      },
      2021: {
        inhabitants: 2.8,
        commuters: 3.22,
        visitors: 12,
      },
      2022: {
        inhabitants: 3.1,
        commuters: 3.565,
        visitors: 14,
      },
      2023: {
        inhabitants: 3.4,
        commuters: 3.91,
        visitors: 16,
      },
      2024: {
        inhabitants: 3.7,
        commuters: 4.255,
        visitors: 18,
      },
      2025: {
        inhabitants: 4.0,
        commuters: 4.6,
        visitors: 20,
      },
      2026: {
        inhabitants: 4.0,
        commuters: 4.6,
        visitors: 20,
      },
      2027: {
        inhabitants: 4.0,
        commuters: 4.6,
        visitors: 20,
      },
      2028: {
        inhabitants: 4.0,
        commuters: 4.6,
        visitors: 20,
      },
      2029: {
        inhabitants: 4.0,
        commuters: 4.6,
        visitors: 20,
      },
      2030: {
        inhabitants: 4.0,
        commuters: 4.6,
        visitors: 20,
      },
    },
    'private': {
      2020: {
        inhabitants: 1,
        commuters: 2.0,
        visitors: 5,
      },
      2021: {
        inhabitants: 1,
        commuters: 2.4,
        visitors: 6,
      },
      2022: {
        inhabitants: 1,
        commuters: 2.8,
        visitors: 7,
      },
      2023: {
        inhabitants: 1,
        commuters: 3.2,
        visitors: 8,
      },
      2024: {
        inhabitants: 1,
        commuters: 3.6,
        visitors: 9,
      },
      2025: {
        inhabitants: 1,
        commuters: 4.0,
        visitors: 10,
      },
      2026: {
        inhabitants: 1,
        commuters: 4.0,
        visitors: 10,
      },
      2027: {
        inhabitants: 1,
        commuters: 4.0,
        visitors: 10,
      },
      2028: {
        inhabitants: 1,
        commuters: 4.0,
        visitors: 10,
      },
      2029: {
        inhabitants: 1,
        commuters: 4.0,
        visitors: 10,
      },
      2030: {
        inhabitants: 1,
        commuters: 4.0,
        visitors: 10,
      },
    },
  },
}
