<template>
  <MapBoxPopup
    :coordinates="coordinatesType"
    :offset="[0, 0]"
    :show="show"
    :anchor="popupAnchor"
    @close="handleClose"
  >
    <div class="Popup--TwoStepGeocoding">
      <!-- needs to be placed in a global manner for the popup, so it's not removed when in editing mode -->
      <MapLineWithDistance
        v-if="!hasParkinglotLayer"
        :from-coordinates="coordinates"
        :to-points="[geocodedCoordinates]"
      />

      <!-- Loading -->
      <Step v-if="isLoading">
        <div class="d-flex align-items-center">
          Loading...
          <b-spinner
            variant="primary"
            small
          />
        </div>
      </Step>

      <!-- Step 1 -->
      <Step
        v-else-if="step === 1"
        :title="
          isAlternativeLocation ? 'Alternatieve locatie' : 'Nieuwe Locatie'
        "
      >
        <button
          v-if="hasParkinglotLayer"
          class="mapboxgl-popup-close-button parkinglot-popup"
          type="button"
          aria-label="Close popup"
          aria-hidden="true"
          @click="handleCancelParkinglots"
        >
          ×
        </button>
        <Form @submit.prevent="fetchAddress">
          <FormField
            v-model="fields.remark.value"
            v-bind="fields.remark"
            :rows="3"
          />
          <FormField
            v-model="fields.phase.value"
            v-bind="fields.phase"
            :options="phases"
            style="pointer-events: auto"
          />
          <FormField
            v-model="fields.status.value"
            v-bind="fields.status"
            :options="statusOptions"
            @input="setChargingPointCategory"
          />
          <div v-if="groupLayers.length > 1">
            <CollapsableSegment
              v-if="hasParkinglotLayer"
              :label="chargingPointLabel"
              :visible="collapseActive"
              class="status-category"
            >
              <ChargingpointsLayerGroup
                :layer="layer"
                :group-layers="groupLayers"
                :selected-status="selectedStatus"
                :is-legend="false"
                @input="handleInput"
              />
            </CollapsableSegment>
            <div v-else>
              <strong
                class="d-flex justify-content-center"
                :style="chargingPointLabelStyle"
              >
                {{ chargingPointLabel }}
              </strong>
              <ChargingpointsLayerGroup
                :layer="layer"
                :group-layers="groupLayers"
                :selected-status="selectedStatus"
                :is-legend="false"
                @input="handleInput"
              />
            </div>
          </div>

          <FormField
            v-model="fields.cpo.value"
            v-bind="fields.cpo"
            :disabled="cpoSelectOptions.length === 1"
            :options="cpoSelectOptions"
          />
          <ParkingLot
            v-if="hasParkinglotLayer"
            :coordinates="coordinatesType"
            @parkinglotIds="(ids) => (fields.parkinglotIds.values = ids)"
            @setCoordinates="($event) => $emit('setCoordinates', $event)"
          />
          <AlternativeLocation
            v-if="isAlternativeLocation"
            :predecessor="fields.predecessor.value"
            @select="(value) => (fields.predecessor.value = value)"
          />
          <div
            v-if="hasParkinglotLayer"
            class="anchor-tool"
          >
            <component
              :is="`b-icon-arrow-${icon.type}-circle${
                popupAnchor === icon.position ? '-fill' : ''
              }`"
              v-for="icon in anchorIcons"
              :key="icon.type"
              @click="handleAnchor({ position: icon.position })"
            />
          </div>
          <b-button
            type="submit"
            class="mt-2 float-right"
            size="sm"
            variant="primary"
            :disabled="isDisabled"
          >
            Volgende
          </b-button>
        </Form>
      </Step>

      <!-- Step 2 -->
      <Step
        v-else-if="step === 2"
        :title="hasAddress ? 'Adres bevestigen' : 'Voer het adres handmatig in'"
        :has-address="hasAddress"
      >
        <template #default>
          <div v-if="isEditing">
            <Form>
              <FormField
                v-model.trim="fields.address.street.value"
                v-bind="fields.address.street"
              />
              <div class="row">
                <div class="col-6">
                  <FormField
                    v-model.trim="fields.address.number.value"
                    v-bind="fields.address.number"
                  />
                </div>
                <div class="col-6">
                  <FormField
                    v-model.trim="fields.address.postalcode.value"
                    v-bind="fields.address.postalcode"
                  />
                </div>
              </div>
              <FormField
                v-model.trim="fields.address.city.value"
                v-bind="fields.address.city"
              />
            </Form>
          </div>

          <div v-else>
            <button
              v-if="hasParkinglotLayer"
              class="mapboxgl-popup-close-button parkinglot-popup"
              type="button"
              aria-label="Close popup"
              aria-hidden="true"
              @click="handleCancelParkinglots"
            >
              ×
            </button>
            <strong>Adres</strong>
            <p class="mb-2">
              {{ address.formatted_address }}
            </p>

            <strong>Adresbron</strong>
            <p v-if="address.source === SOURCE.MANUAL">
              <span class="text-success">{{ sourceSlug }}</span>
            </p>

            <p v-if="address.source !== SOURCE.MANUAL">
              {{ sourceSlug }} <br>
              Afstand:
              <span :class="distanceIndicationClassName">{{ distance }}m</span>
            </p>
          </div>
        </template>

        <template #footer>
          <template v-if="isEditing">
            <b-button
              size="sm"
              variant="danger"
              @click="handleCancel"
            >
              Annuleren
            </b-button>

            <b-button
              size="sm"
              class="ml-1"
              variant="primary"
              @click="handleUpdateAddress"
            >
              Overnemen
            </b-button>
          </template>

          <template v-else>
            <b-button
              size="sm"
              variant="danger"
              @click="isEditing = true"
            >
              Aanpassen
            </b-button>

            <b-button
              size="sm"
              class="ml-1"
              variant="primary"
              @click="handleSubmit"
            >
              {{ isUpdate ? "Bevestigen" : "Toevoegen" }}
            </b-button>
          </template>
        </template>
      </Step>
    </div>
  </MapBoxPopup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

// components
import MapBoxPopup from '@/components/common/MapBoxPopup'
import MapLineWithDistance from '@/components/map/MapLineWithDistance'
import FormField from '@/components/form/FormField'
import Form from '@/components/form/Form'
import Step from '@/components/common/Step'
import AlternativeLocation from '@/components/map/sidebar/AlternativeLocation'
import ChargingpointsLayerGroup from '@/components/common/ChargingpointsLayerGroup.vue'
import ParkingLot from '@/components/common/ParkingLot'
import CollapsableSegment from '@/components/common/CollapsableSegment'
import {
  BIconArrowLeftCircle,
  BIconArrowRightCircle,
  BIconArrowUpCircle,
  BIconArrowDownCircle,
  BIconArrowLeftCircleFill,
  BIconArrowRightCircleFill,
  BIconArrowUpCircleFill,
  BIconArrowDownCircleFill,
} from 'bootstrap-vue'

// mixins
import chargingpointCpoMixin from '@/mixins/chargingpoint/chargingpointCpoMixin'
import statusMixin from '@/mixins/common/statusMixin'
import mapBoxMixin from '@/components/common/MapBoxMixin'

// services
import { SOURCE, sourceToSlug } from '@/services/sourceTranslation'
import { turf } from '@/services/turf'
import { createMarker } from '@/helpers/mapbox'
import { validate } from 'uuid'
import { provincieCodeByMunicipalityCode } from '@/services/municipalities'


// shared
import { dynamicReverseGeocode } from '@/../shared/services/geocoder'
import { distanceBetweenTwoPoints } from '@/../shared/helpers/measuring'
import {
  formatFormattedAddress,
  formatSimpleAddress,
} from '@/../shared/helpers/address'
import { ADDRESS_QUALITY_DISTANCE } from '@/../shared/valueholders/address-quality-distance'

import getCssProperty from '@/helpers/cssProperty'

// locale values
const START_STEP = 1

export default {
  name: 'ChargerTwoStepGeocodingPopup',
  components: {
    Step,
    MapBoxPopup,
    Form,
    FormField,
    MapLineWithDistance,
    AlternativeLocation,
    ChargingpointsLayerGroup,
    ParkingLot,
    BIconArrowLeftCircle,
    BIconArrowRightCircle,
    BIconArrowUpCircle,
    BIconArrowDownCircle,
    BIconArrowLeftCircleFill,
    BIconArrowRightCircleFill,
    BIconArrowUpCircleFill,
    BIconArrowDownCircleFill,
    CollapsableSegment,
  },
  mixins: [mapBoxMixin, chargingpointCpoMixin, statusMixin],
  props: {
    coordinates: {
      type: Array,
      required: true,
    },
    isUpdate: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    firstSelectedParkingCoordinates: {
      type: Array,
      default: () => [],
    },
    popupAnchor: {
      type: String,
      default: 'left',
    },
  },
  data() {
    return {
      // state
      show: true,
      isLoading: false,
      isEditing: false,

      // helpers for two-step creation of a chargingpoint
      step: START_STEP,
      distance: null,

      // this is needed to handle the close event when the popup is re-created when the anchor changes
      anchor: 'left',
      keepAlive: false,

      // start value fields
      fields: {
        address: {
          street: {
            label: this.$i18n.t('address.street'),
            value: null,
          },
          number: {
            label: this.$i18n.t('address.houseNumber'),
            value: null,
          },
          postalcode: {
            label: this.$i18n.t('address.zipCode'),
            value: null,
          },
          city: {
            label: this.$i18n.t('address.city'),
            value: null,
          },
        },
        remark: {
          label: this.$i18n.t('remark'),
          value: '',
          type: 'textarea',
        },
        status: {
          label: this.$i18n.t('mapLayersPanel.status.title'),
          value: 'suggestion',
          type: 'select',
        },
        phase: {
          label: this.$i18n.t('phase'),
          value: null,
          type: 'select',
        },
        predecessor: {
          label: this.$i18n.t(
            'components.sidebar.LocatieInformatieContent.alternative',
          ),
          value: null,
        },
        parkinglotIds: {
          label: this.$i18n.t(
            'mapLayersPanel.municipality.parkingSpacesAmsterdam.title',
          ),
          values: [],
        },
      },

      // address and coordinates that will be used for creation of the location
      address: 0,
      phases: [],
      geocodedCoordinates: null,
      selectedStatus: 'chargingpoints-suggestion',
      chargingPointLabel: 'Voorgesteld',
      anchorIcons: [
        { type: 'left', position: 'left' },
        { type: 'up', position: 'top' },
        { type: 'right', position: 'right' },
        { type: 'down', position: 'bottom' },
      ],
      collapseActive: false,
    }
  },
  computed: {
    ...mapGetters('access', ['getActiveMunicipality']),
    ...mapGetters('planmode', ['getRejectedLocationUuid', 'getPlanmodeLayers']),
    ...mapGetters('config', ['getPhases']),
    isBraLiMunicipality() {
      return ([30, 31].includes(provincieCodeByMunicipalityCode({ code: this.getActiveMunicipality })))
    },
    hasParkinglotLayer() {
      return !!this.$store.map.getLayer('parkinglot')
    },
    sourceSlug() {
      return sourceToSlug({ source: this.address.source })
    },
    distanceIndicationBootstrapColor() {
      if (this.distance <= ADDRESS_QUALITY_DISTANCE.CORRECT) return 'success'
      if (this.distance <= ADDRESS_QUALITY_DISTANCE.UNCERTAIN) return 'warning'
      return 'danger'
    },
    distanceIndicationClassName() {
      return `text-${this.distanceIndicationBootstrapColor}`
    },
    isAlternativeLocation() {
      return !!this.fields.predecessor.value
    },
    groupLayers() {
      return this.getPlanmodeLayers?.filter(
        (layer) => layer.config.statusGroup === this.fields.status.value,
      )
    },
    layer() {
      return this.groupLayers[0]
    },
    chargingPointLabelStyle() {
      return `border-bottom: 1px solid ${this.layer.config.iconColor}`
    },
    hasAddress() {
      return Object.values(this.fields.address).every((key) => !!key.value)
    },
    isDisabled() {
      return (
        this.hasParkinglotLayer &&
        !this.fields.parkinglotIds.values.some((v) => !!v)
      )
    },
    coordinatesType() {
      return this.hasParkinglotLayer
        ? this.firstSelectedParkingCoordinates
        : this.coordinates
    },
  },
  async created() {
    this.fields.predecessor.value = this.getRejectedLocationUuid
      ? { uuid: this.getRejectedLocationUuid }
      : null

    this.SOURCE = SOURCE

    this.step = START_STEP

    this.configurePhases()

    if (this.isUpdate) {
      await this.fetchAddress()
    }
  },
  methods: {
    ...mapActions('planmode', ['addOrUpdateChargingPoint', 'setParkingLotIds']),
    configurePhases() {

      const oldPhases = [
        { value: 1, label: 'Fase 1' },
        { value: 2, label: 'Fase 2' },
      ]
      const newActivePhases =
        this.getPhases
        ?.filter(({ active }) => !!active)
          .map(({ uuid, label }) => ({ value: uuid, label })) ?? []

      const activePhases = [...oldPhases, ...newActivePhases]

      activePhases.forEach(phase => {
        this.phases.push({ value: phase.value, text: phase.label })
      })

      if (this.isBraLiMunicipality === true){
        this.fields.phase.value = activePhases.find(phase => phase.label === 'Fase 3B')?.value
      }
    },
    /**
     * handle step 1
     */
    async fetchAddress() {
      this.isLoading = true

      this.address = await dynamicReverseGeocode({
        lng: this.coordinates[0],
        lat: this.coordinates[1],
      }).catch((e) => {
        if (e === 'not found') {
          // Geocoder found no address at the selected location, we switch to manual input //
          return
        }

        this.$notify({
          type: 'error',
          title: 'Adres ophalen niet mogelijk!',
          text: 'Er is geen connectie met de geocoder API. Probeer het later opnieuw.',
        })
      })

      let geocodedCoordinates

      if (this.address) {
        // prepare already for editing
        this.fields.address.street.value = this.address.street
        this.fields.address.number.value = this.address.number
        this.fields.address.postalcode.value = this.address.postalcode
        this.fields.address.city.value = this.address.city

        // calculate distance
        if (this.hasParkinglotLayer) {
          geocodedCoordinates = this.firstSelectedParkingCoordinates
        } else {
          geocodedCoordinates = [
            this.address.coordinates.lng(),
            this.address.coordinates.lat(),
          ]
          this.distance = distanceBetweenTwoPoints(
            this.coordinates,
            geocodedCoordinates,
          ).toFixed(2)
        }
      } else {
        // No address found, initialize address and continue with manual input //
        this.address = {}
        this.isEditing = true

        geocodedCoordinates = this.hasParkinglotLayer
          ? this.firstSelectedParkingCoordinates
          : [this.coordinates[0], this.coordinates[1]]
      }

      // prepare popup before showing the result, otherwise it will handle a close when the anchor changes, since
      // that'll remove and recreate the popup. By that we will lose the geocoded coordinates due to handleClose
      this.setAnchor({ geocodedCoordinates })

      // wait for next tick so everything is updated
      this.$nextTick(async () => {
        await this.displayResult({ geocodedCoordinates })
      })

      this.isLoading = false
    },

    setChargingPointCategory() {
      this.selectedStatus = this.layer.id
      this.chargingPointLabel = this.layer.label
    },

    handleInput(chargingPoint) {
      this.selectedStatus = chargingPoint.layer.id
      this.chargingPointLabel = chargingPoint.layer.label
    },

    handleCancelParkinglots() {
      this.$store.dispatch('planmode/setParkingLotIds', { ids: [] })
      this.fields.parkinglotIds.values = []
      this.$emit('setCoordinates', null)
      this.$emit('setAnchor', { position: 'left' })
      this.handleClose()
    },

    handleAnchor({ position }) {
      this.keepAlive = true
      this.anchor = position
      this.$emit('setAnchor', { position })
    },

    /**
     * handle step 2
     */
    async handleSubmit() {
      const isCustomPhase = validate(this.fields.phase.value)
      const phase = {
        phase: isCustomPhase ? null : this.fields.phase.value,
        customPhase: isCustomPhase ? this.fields.phase.value : null,
      }

      if (this.isUpdate) {
        this.$emit('update', {
          address: this.address,
          cpo: this.fields.cpo.value,
        })
      } else {
        this.$emit('create', {
          status: this.selectedStatus.replace('chargingpoints-', ''),
          address: this.address,
          cpo: this.fields.cpo.value,
          remark: this.fields.remark.value,
          predecessor: this.fields.predecessor.value,
          parkinglotIds: this.fields.parkinglotIds.values.filter(Boolean),
          prio: {
            ...phase,
          },
        })
      }

      this.$emit('close')
    },

    /**
     * handle close event of the popup
     */
    handleClose() {
      this.creationMarker?.remove()
      this.geocodedCoordinates = null

      if (!this.keepAlive) {
        this.$emit('cancel')
      }
    },

    handleCancel() {
      this.hasAddress
        ? (this.isEditing = false)
        : (this.handleClose(), this.handleCancelParkinglots())
    },

    /**
     * update the address object with data from the form
     */
    handleUpdateAddress() {
      /* update address */
      this.address.source = SOURCE.MANUAL
      this.address.street = this.fields.address.street.value
      this.address.number = this.fields.address.number.value
      this.address.postalcode = this.fields.address.postalcode.value
      this.address.city = this.fields.address.city.value
      this.address.simple_address = formatSimpleAddress({
        address: this.address,
      })
      this.address.formatted_address = formatFormattedAddress({
        address: this.address,
      })

      /* remove CTAs, since they are not accurate anymore */
      this.creationMarker.remove()
      this.geocodedCoordinates = null

      // update ui
      this.isEditing = false
    },

    /**
     * find the anchor by calculating the bearing and placing it the other direction
     */
    setAnchor({ geocodedCoordinates }) {
      if (!(this.coordinates.length && geocodedCoordinates.length))
        return false

      this.keepAlive = true

      const bearing = turf.bearing(this.coordinates, geocodedCoordinates)
      if (bearing) {
        // if line directs downwards, place popup above
        if (bearing > -90 && bearing < 90) {
          this.anchor = 'top'
          return
        }

        // if line directs upwards, place popup beneath
        this.anchor = 'bottom'
        return
      }

      // use mapbox default (auto placement)
      this.anchor = ''
    },

    /**
     * display result accordingly
     */
    async displayResult({ geocodedCoordinates }) {
      // by setting the coordinates, the line with distance indication will have sufficient points and therefore be displayed
      this.geocodedCoordinates = geocodedCoordinates

      // Create a marker for the geocoded address to display the exact point
      this.creationMarker = await createMarker({
        coordinates: geocodedCoordinates,
        options: {
          color: getCssProperty(this.distanceIndicationBootstrapColor),
        },
        map: this.map,
      })

      await this.zoomToLine()

      this.keepAlive = false

      this.goToNextStep()
    },

    goToNextStep() {
      ++this.step
    },

    /**
     * zoom to the line so that the line to geocoded address is visible
     */
    async zoomToLine() {
      const { default: mapboxgl } = await import('mapbox-gl') // Dynamically import mapbox-gl

      const bounds = new mapboxgl.LngLatBounds(
        this.coordinates,
        this.geocodedCoordinates,
      )

      this.map.fitBounds(bounds, {
        padding: 250,
        maxZoom: 19,
      })
    },
  },
}
</script>

<style lang="scss">
.FormField label,
.FormField legend {
  font-size: 12px;
}
.parkinglot-popup {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: transparent;
  z-index: 1;
}
.anchor-tool {
  position: absolute;

  .b-icon {
    cursor: pointer;
    font-size: 16px;
    &.bi-arrow-down-circle,
    &.bi-arrow-down-circle-fill {
      display: block;
      margin: -0.5em 0 0 1em;
    }
    &.bi-arrow-up-circle,
    &.bi-arrow-up-circle-fill {
      margin-bottom: 0.6em;
    }
  }
}
.Popup--TwoStepGeocoding {
  .CollapsableSegment__Header {
    padding-bottom: 0.2em;
    margin-bottom: 0.6em;
    > span {
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      white-space: nowrap;
    }
    .svg-inline--fa {
      height: 12px;
    }
  }
}
</style>
