<template>
  <div class="MunicipalityPanel mt-3 mb-4 flex-grow-1">
    <div class="position-sticky">
      <PanelHeader
        :enable-save="difference"
        :busy="busy"
        @save="handleSaveEvent"
      />
      <Tabs
        :tabs="tabs"
        :active="activeTab"
        @selected="handleTabClick"
      />
    </div>

    <Feedback
      v-if="!isStored"
      class="mx-4 mt-4"
      :dismissible="false"
      :feedback="{
        message: $t('components.municipalityPanel.firstConfigMsg'),
        variant: 'warning',
      }"
    />

    <section
      v-if="activeTab === 'layers'"
      class="MunicipalityPanel__Section d-flex flex-column p-4"
    >
      <LayerConfigPanel
        :value="layers"
        class="mt-4 pb-5"
        @input="handleLayersChange"
      />
    </section>

    <section
      v-if="activeTab === 'monitoring'"
      class="MunicipalityPanel__Section d-flex flex-column p-4"
    >
      <MonitoringPanel
        :values="monitoring"
        class="mt-4 pb-5"
        @input="handleMonitoringChange"
      />
    </section>

    <section
      v-if="activeTab === 'prognose'"
      class="MunicipalityPanel__Section d-flex flex-column p-4"
    >
      <FeaturePanel
        :values="features"
        :has-layers="!noActiveLayers"
        class="mt-4 pb-5"
        @input="handleFeaturesChange"
      />
      <PrognosePanel
        :values="prognose"
        class="mt-4 pb-5"
        @input="handlePrognoseChange"
      />
    </section>

    <section
      v-if="activeTab === 'chargingpoints'"
      class="MunicipalityPanel__Section d-flex flex-column p-4"
    >
      <AssignValidatorsPanel />
    </section>

    <section
      v-if="activeTab === 'participation'"
      class="MunicipalityPanel__Section d-flex flex-column p-4"
    >
      <ParticipationPanel
        :values="participation"
        class="mt-4 pb-5"
        @input="handleParticipationChange"
      />
    </section>
  </div>
</template>

<script>
import PanelHeader from '@/components/common/PanelHeader'
import LayerConfigPanel from '@/components/admin/municipalities/LayerConfigPanel'
import FeaturePanel from '@/components/admin/municipalities/FeaturePanel'
import PrognosePanel from '@/components/admin/municipalities/PrognosePanel'
import ParticipationPanel from '@/components/admin/municipalities/ParticipationPanel'
import AssignValidatorsPanel from '@/components/admin/chargingpoints/AssignValidatorsPanel'
import MonitoringPanel from '@/components/admin/municipalities/MonitoringPanel'

import Tabs from '@/components/common/Tabs'
import Feedback from '@/components/form/Feedback'

import { labelByCode, slugByCode } from '@/services/municipalities'
// use raw data
import { layers as layerDetails } from '@/data/layerDetails'

import userMixin from '@/mixins/common/userMixin'
import chargingpointsLoadMixin from '@/mixins/chargingpoint/chargingpointsLoadMixin'
import chargingpointEditMixin from '@/mixins/chargingpoint/chargingpointEditMixin'

import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import { Bugfender } from '@bugfender/sdk'
import { checkStatus, returnJson } from '@/helpers/api'
import * as uuid from 'uuid'

export default {
  components: {
    AssignValidatorsPanel,
    PanelHeader,
    LayerConfigPanel,
    FeaturePanel,
    PrognosePanel,
    ParticipationPanel,
    MonitoringPanel,
    Tabs,
    Feedback,
  },
  mixins: [userMixin, chargingpointsLoadMixin, chargingpointEditMixin],
  data() {
    return {
      // UI States
      activeTab: 'prognose',
      tabs: [
        {
          label: this.$i18n.t('municipalityPanelTitle.featuresForecast'),
          id: 'prognose',
        },
        {
          label: this.$i18n.t('municipalityPanelTitle.mapLayers'),
          id: 'layers',
        },
        {
          label: this.$i18n.t('municipalityPanelTitle.validation'),
          id: 'chargingpoints',
        },
        {
          label: this.$i18n.t('municipalityPanelTitle.participation'),
          id: 'participation',
        },
        {
          label: 'Monitoring',
          id: 'monitoring',
        },
      ],
      difference: false,
      isStatusesDifference: false,
      isClosingDateDifference: false,
      busy: false,

      // Datasets for the panels
      layers: {},
      prognose: {},
      monitoring: {},
      planmode: {},
      participation: {},
      upload: {},
      factsheet: {},
      bedrijventerreinen: {},
      export: {},
      phases: [],
    }
  },
  computed: {
    ...mapGetters('access', ['getActiveMunicipality']),
    ...mapGetters('planmode', ['getChargingPoints']),
    ...mapGetters('config', {
      config: 'config',
      participationConfigs: 'participationConfigs',
      isStored: 'isStored',
      mapOptions: 'mapOptions',
      configYears: 'years',
      configDefaultYear: 'defaultYear',
      configLimit: 'limit',
      configModels: 'models',
      configBreakpoints: 'breakpoints',
      configLayers: 'layers',
      configPlanmode: 'planmode',
      configPrognoseEnabled: 'isPrognoseEnabled',
      configUploadEnabled: 'isUploadEnabled',
      configFactsheetEnabled: 'isFactsheetEnabled',
      configBedrijventerreinenEnabled: 'isBedrijventerreinenEnabled',
      configExportEnabled: 'isExportEnabled',
      configMonitoringEnabled: 'isMonitoringEnabled',
      configMonitoringChargePointIdType: 'chargePointIdType',
      configMonitoringLastMonth: 'lastMonth',
      configMonitoringNotificationEmailAddresses: 'notificationEmailAddresses',
      configMonitoringCpos: 'cpos',
      configThresholds: 'thresholds',
    }),
    closingDateParticipation() {
      return this.participationConfigs[0]?.closeDate ?? null
    },
    hasConfigVoltLockedKey () {
      return Object.hasOwn(this.config, 'isVoltLocked')
    },

    /**
     * The model data for the Features panel
     */
    features() {
      return {
        prognose: this.prognose.enabled,
        planmode: this.planmode.enabled,
        upload: this.upload.enabled,
        factsheet: this.factsheet.enabled,
        bedrijventerreinen: this.bedrijventerreinen.enabled,
        export: this.export.enabled,
        phases: this.phases,
      }
    },
    /**
     * The config structure, as stored in FaunaDB.
     */
    data() {
      return {
        code: this.getActiveMunicipality,
        name: labelByCode({
          code: this.getActiveMunicipality,
        }),
        // Either the upload portal or the prognose needs to be available for a municipality to be active
        enabled: this.upload.enabled || this.prognose.enabled,
        planmode: this.planmode.enabled,
        upload: this.upload.enabled,
        factsheet: this.factsheet.enabled,
        bedrijventerreinen: this.bedrijventerreinen.enabled,
        phases: this.phases,
        export: {
          enabled: this.export.enabled,
        },
        monitoring: {
          enabled: this.monitoring.enabled,
          chargePointIdType: this.monitoring.chargePointIdType,
          lastMonth: this.monitoring.lastMonth,
          notificationEmailAddresses: this.monitoring.notificationEmailAddresses,
          cpos: this.monitoring.cpos,
          thresholds: this.monitoring.thresholds,
        },
        participation: this.participation.map(config => ({
          uuid: config.uuid,
          enabled: config.enabled,
          interactionEnabled: config.interactionEnabled,
          interactionOnPastParticipations: config.interactionOnPastParticipations,
          suggestionsEnabled: config.suggestionsEnabled,
          defaultLayerVisibility: config.defaultLayerVisibility,
          slug: config.slug,
          openDate: config.openDate,
          closeDate: config.closeDate,
          allClosingDates: config.allClosingDates ?? [],
          previewCode: config.previewCode,
          previewEnabled: config.previewEnabled,
          showMessageAfterClose: config.showMessageAfterClose,
          closingMessage: config.closingMessage,
          logoBackgroundColor: config.logoBackgroundColor,
          translationsEnabled: config.translationsEnabled ?? false,
          showPolicy: config.showPolicy,
          policyText: config.policyText,
          statuses: config.statuses,
          phases: config.phases,
          cpos: config.cpos,
          introductionText: config.introductionText,
          sidebarText: config.sidebarText,
          geography: config.geography,
        })),
        prognose: {
          enabled: this.prognose.enabled,
          limit: this.prognose.limit,
          years: this.prognose.years,
          defaultYear: this.prognose.defaultYear,
          breakpoints: [
            this.prognose.breakpoint_one,
            this.prognose.breakpoint_two,
            this.prognose.breakpoint_three,
          ],
          models: this.prognose.models,
        },
        map: this.mapOptions,
        layers: this.layers,
      }
    },
    /**
     * Detect when there is no layer configuration at all
     */
    noActiveLayers() {
      return Object.values(this.layers)
        .filter(layer => layer.status !== 'hidden').length === 0
    },
  },
  watch: {
    /**
     * Upon changing the active municipality, the config is reloaded
     *  TODO: Implement warning notice if anything has changed?
     *  TODO: WebWorkers (Cloudflare workers - evmaps@journeyworks.nl account) to support multiple users editing a config simulteanously
     */
    getActiveMunicipality() {
      this.loadConfig()
    },

    /**
     * Reload if anything changes in the central config store (e.g. config is succesfully saved)
     *  We want to show the actual stored changes. Should something have gone wrong it should be
     *  noticeable right away.
     */
    config() {
      this.loadConfig()
    },
    noActiveLayers() {
      if (this.noActiveLayers) {
        this.prognose.enabled = false
      }
    },
    activeTab: {
      handler () {
        this.loadConfig()
        this.difference = false
      },
    },
  },
  created() {
    this.loadConfig()
  },
  methods: {
    ...mapMutations('config', ['updateOrAddConfig']),
    /**
     * Handle a change in tabs
     */
    handleTabClick({ id }) {
      this.activeTab = id
    },
    /**
     * Get configuration details from the config store
     */
    loadConfig() {
      this.prognose = {
        enabled: this.configPrognoseEnabled,
        years: this.configYears,
        defaultYear: this.configDefaultYear,
        limit: this.configLimit,
        models: this.configModels,
        breakpoint_one: this.configBreakpoints[0],
        breakpoint_two: this.configBreakpoints[1],
        breakpoint_three: this.configBreakpoints[2],
      }

      this.planmode = {
        enabled: !! this.configPlanmode,
      }
      this.upload = {
        enabled: !! this.configUploadEnabled,
      }
      this.factsheet = {
        enabled: !! this.configFactsheetEnabled,
      }
      this.bedrijventerreinen = {
        enabled: !! this.configBedrijventerreinenEnabled,
      }
      this.export = {
        enabled: !! this.configExportEnabled,
      }
      this.monitoring = {
        enabled: !! this.configMonitoringEnabled,
        chargePointIdType: this.configMonitoringChargePointIdType,
        lastMonth: this.configMonitoringLastMonth,
        cpos: this.configMonitoringCpos,
        notificationEmailAddresses: this.configMonitoringNotificationEmailAddresses,
        thresholds: this.configThresholds,
      }

      const defaultPolicyText = (this.$i18n.t('participationPanel.defaultPolicy.example')).trim().split('\n').map(line => line.trim()).join('\n')

      const defaultClosingMessage = (this.$i18n.t('participationPanel.textClosingMessage.example')).trim().split('\n').map(line => line.trim()).join('\n')

      const defaultIntroductionText = (this.$i18n.t('participationPanel.defaultIntro.example')).trim().split('\n').map(line => line.trim()).join('\n')

      const defaultSidebarText = (this.$i18n.t('participationPanel.defaultSidebar.example')).trim().split('\n').map(line => line.trim()).join('\n')

      // Set a default participation config if there are none
      const participationConfigs = this.participationConfigs.length ? this.participationConfigs : [{}]
      this.participation = participationConfigs.map(config => ({
        uuid: config.uuid ?? uuid.v4(),
        enabled: !!config.enabled,
        interactionEnabled: !!config.interactionEnabled,
        interactionOnPastParticipations: !!config.interactionOnPastParticipations,
        suggestionsEnabled: !!config.suggestionsEnabled,
        defaultLayerVisibility: {
          suggestion: !!config.defaultLayerVisibility?.suggestion,
        },
        slug: config.slug ?? slugByCode({ code: this.getActiveMunicipality }),
        openDate: config.openDate,
        closeDate: config.closeDate,
        allClosingDates: config.allClosingDates ?? [],
        previewCode: config.previewCode ?? (Math.random() + 1).toString(36).substring(6),
        previewEnabled: config.previewEnabled ?? false,
        showMessageAfterClose: !!config.showMessageAfterClose,
        closingMessage: config.closingMessage ?? defaultClosingMessage,
        logoBackgroundColor: config.logoBackgroundColor ?? '#FFFFFF',
        showPolicy: !!config.showPolicy,
        policyText: config.policyText ?? defaultPolicyText,
        translationsEnabled: config.translationsEnabled ?? false,
        statuses: config.statuses ?? ['realized', 'in-progress', 'definitive'],
        phases: config.phases ?? [],
        cpos: config.cpos ?? [],
        introductionText: config.introductionText ?? defaultIntroductionText,
        sidebarText: config.sidebarText ?? defaultSidebarText,
        geography: config.geography,
      }))

      this.layers = this.configLayers
      this.phases = this.config.phases

      // Ensure that all context layers are present as Observable properties. This allows the UI to function properly
      // TODO: Find a better way to get the same result
      layerDetails
        .filter((layer) => ['context'].includes(layer.type))
        .forEach((layer) => {
          if (this.layers[layer.id]) return
          Vue.set(this.layers, layer.id, {
            source: '',
            status: 'hidden',
            url: '',
          })
        })

      if (this.noActiveLayers) {
        this.prognose.enabled = false
      }

      /**
       * Normally re-loading the config disables the save button, because there is no dirty data.
       *  However, in case of a config that has not yet been stored, and has been provided with
       *  layer data (automated or manually), the save button is enabled immediately.
       *  Addition: unless the upload portal mode is enabled
       */
      this.difference = ! this.isStored && (! this.noActiveLayers || this.upload.enabled)

      // Update the UI State
      this.busy = false
    },
    /**
     * Handle config changes from the panel
     */
    handleLayersChange(layers) {
      this.layers = layers

      this.difference = true
    },
    handlePrognoseChange(data) {
      this.prognose = data
      this.difference = true
    },
    handleMonitoringChange(data) {
      this.monitoring = data
      this.difference = true
    },
    handleFeaturesChange({ data, difference }) {

      if (difference) {
        this.planmode.enabled = data.planmode
        this.prognose.enabled = data.prognose
        this.upload.enabled = data.upload
        this.factsheet.enabled = data.factsheet
        this.bedrijventerreinen.enabled = data.bedrijventerreinen
        this.export.enabled = data.export
        this.phases = data.phases
        if (data.hasVoltLocations) {
          this.data.isVoltLocked = data.isVoltLocked
        } else {
          delete this.data.isVoltLocked
        }
      }
      this.difference = difference
    },
    handleParticipationChange(data) {
      this.participation = data
      this.difference = true

      this.isStatusesDifference = this.participation[0].statuses !== this.config.participation[0].statuses
      this.isClosingDateDifference = this.participation[0].closeDate !== this.config.participation[0].closeDate
    },
    async updateParticipationDatesStations() {
      const chargingPoints =
        await this.$_chargingpointsLoadMixin_getChargingPointsByCodeStatus({
          code: this.getActiveMunicipality,
          statuses: this.participation[0].statuses,
        })

      for (const chargingPoint of chargingPoints) {
        const chargingpointData = chargingPoint.data
        const existingDates = Array.isArray(chargingpointData.participation?.closingDates)
          ? chargingpointData.participation.closingDates : []

        if ((this.closingDateParticipation != null || this.closingDateParticipation != '') && existingDates.includes(this.closingDateParticipation)) {
          continue
        } else if (this.closingDateParticipation == null || this.closingDateParticipation == '') {
          break
        }

        const closingDatesArray = [...existingDates, this.closingDateParticipation]

        await this.$_chargingpointEditMixin_save({
          data: {
            code: chargingpointData.code,
            ref: chargingPoint.ref, // full ref
            id: chargingpointData.properties.id,
            stakeholders: chargingpointData.properties.stakeholders,
            status: chargingpointData.properties.status,
            user: {
              name:
                chargingpointData.properties.user_name ??
                chargingpointData.properties.user?.name,
            },
            coordinates: chargingpointData.coordinates,
            remark: chargingpointData.properties.remark,
            beheerder: chargingpointData.beheerder,
            predecessor: chargingpointData.properties.predecessor,
            validators: chargingpointData.validators,
            address: chargingpointData.address,
            prio: chargingpointData.prio,
            participation: {
                closingDates: closingDatesArray,
            },
          },
        })
      }
    },
    handleParticipationClosingDates() {
      const existingDates = Array.isArray(this.participationConfigs[0].allClosingDates)
        ? this.participationConfigs[0].allClosingDates: []

        if (!existingDates.includes(this.participation[0].closeDate)) {
        const closingDatesArray = [...existingDates, this.participation[0].closeDate]
        this.participation[0].allClosingDates = closingDatesArray
      } else {
        return
      }
    },
    async saveConfig() {
      const token = await this.$auth.getTokenSilently()
      const response = await fetch('/api/configsave', {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(this.data),
      })
        .then(await checkStatus)
        .then(returnJson)
        .catch(e => {
          this.$notify({
            type: 'error',
            title: this.$i18n.t('components.municipalityPanel.errorTitle'),
            text: this.$i18n.t('components.municpalityPanel.errorMsg'),
          })
          Bugfender.error('config updaten niet gelukt: ', e)
        })

      if (response.config) {
        this.updateOrAddConfig({
          config: response.config,
        })
      }

      if (this.hasConfigVoltLockedKey && this.getChargingPoints.length) {
        this.$store.dispatch('planmode/setEditabilityBySource', { isLocked: this.config.isVoltLocked, source: 'import-royal-haskoning' })
      }
    },

    /**
     * Store the municipality data
     */
    async handleSaveEvent() {
      this.busy = true

      if (this.isClosingDateDifference) {
        this.handleParticipationClosingDates()
      }
      await this.saveConfig()

      if (this.isStatusesDifference || this.isClosingDateDifference) {
        await this.updateParticipationDatesStations()
      }
    },
  },
}
</script>

<style lang="scss">
.MunicipalityPanel {
  .position-sticky {
    top: 0;
    z-index: 3;
  }
  header {
    background: var(--primary);
    color: white;
    font-size: 1.5rem;
  }

  form {
    label {
      font-size: 1.1rem;
    }
    small {
      outline: none !important;
      font-size: 1rem;
    }
  }
}
</style>
