<template>
  <div class="buttonGroup">
    <b-button
      :class="{ 'buttonGroup--selected': withClosingDate }"
      variant="outline-secondary"
      :value="withClosingDate"
      :disabled="disabled"
      size="sm"
      @click="handleBtnClick('withClosingDate', 'with')"
    >
      {{ $t('components.form.buttons.yes') }}
    </b-button>

    <b-button
      :class="{ 'buttonGroup--selected': withoutClosingDate }"
      variant="outline-secondary"
      :value="withoutClosingDate"
      :disabled="disabled"
      size="sm"
      @click="handleBtnClick('withoutClosingDate', 'without')"
    >
      {{ $t('components.form.buttons.no') }}
    </b-button>

    <b-button
      :class="{ 'buttonGroup--selected': noFilter }"
      variant="outline-secondary"
      :value="noFilter"
      :disabled="disabled"
      size="sm"
      @click="handleBtnClick('noFilter', 'none')"
    >
      {{ $t('components.form.buttons.all') }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'Buttons',
  data() {
    return {
      disabled: false,
      withClosingDate: false,
      withoutClosingDate: false,
      noFilter: true,
    }
  },
  methods: {
    handleBtnClick(button, state) {
      this.withClosingDate = this.withoutClosingDate = this.noFilter = false
      this[button] = true

      this.$emit('click', state)
    },
  },
}
</script>

<style scoped lang="scss">
.buttonGroup {
    &--selected{
  background-color: var(--cta);
  color: white;
    }
}
</style>
