<template>
  <CollapsableSegment
    :visible="false"
    :label="$t('components.sidebar.legend.sources')"
    class="mt-5"
  >
    <template #header>
      <ToggleSwitch
        :value="locationSourcesVisibility"
        class="m-0"
        @input="handleSourcesVisibility"
      />
    </template>
    <b-spinner
      v-if="isLoading"
      small
    />
    <div v-else>
      <div
        v-for="(source, index) in getLocationSources"
        :key="index"
        class="d-flex align-items-center justify-content-between mb-2"
      >
        <span
          style="font-size:1.2rem"
        >
          {{ source.label }}
        </span>
        <b-badge
          variant="light"
          class="ml-auto mr-3 count"
        >
          {{ source.count }}
        </b-badge>
        <ToggleSwitch
          class="m-0"
          :value="source.visibility"
          :bg-color="'#8bc34a'"
          @input="$event => filterLocationsBySource({ value: $event, source: source.type.toString() })"
        />
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2 pt-1 border-top">
        <span
          style="font-size:1.2rem"
        >
          {{ $t('components.sidebar.legend.total') }}
        </span>
        <b-badge
          variant="info"
          class="ml-auto mr-5 count"
        >
          {{ getTotalSourcesCount }}
        </b-badge>
      </div>
    </div>
  </CollapsableSegment>
</template>

<script>

import CollapsableSegment from '@/components/common/CollapsableSegment'
import ToggleSwitch from '@/components/form/ToggleSwitch'
import { mapGetters } from 'vuex'

export default {
  name: 'LegendLocationSources',
  components: {
    CollapsableSegment,
    ToggleSwitch,
  },
  data() {
    return {
      isLoading: true,
      storageVersion: 1,
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('planmode', [
      'getLocationSources',
      'getChargingPoints',
    ]),

    locationSourcesVisibility () {
      return this.getLocationSources.every(source => source.visibility)
    },
    getTotalSourcesCount () {
      return this.getLocationSources.reduce(( total, source ) => total + source.count, 0 )
    },
  },
  watch: {
    getActiveMunicipality: {
      handler () {
        this.isLoading = true
      },
    },
    getLocationSources: {
      immediate: true,
      handler () {
        this.isLoading = !this.getChargingPoints.length // Loading chargingpoints is finished, set to false
      },
    },
  },
  created () {
    const locationSources = JSON.parse(localStorage.getItem('evmaps-locationSources-visibility'))
    if (locationSources) {
      if (this.storageVersion !== locationSources.version) {
        localStorage.removeItem('evmaps-locationSources-visibility')
      }

      this.$store.dispatch('planmode/setLocationSourcesVisibilityFromStorage', { locationSources })
    }
  },
  methods: {
    handleSourcesVisibility (value) {
      this.$store.dispatch('planmode/setLocationSourcesVisibility', { value })
    },
    filterLocationsBySource ({ value, source }) {
      this.$store.dispatch('planmode/filterChargingPointsBySource', { value, source, version: this.storageVersion })
    },
  },
}
</script>
<style lang="scss" scoped>
.count {
  font-weight: 500;
  color: #7F8FA4;
  border: 1px solid #7F8FA4;
  background: white;
}
</style>
