/**
 * Export format to support Node.js scripts
 */
module.exports = {
  cpos: [
    { uuid: '4ba20825-ee3d-4b57-a16c-3d1bdc01970b', type: 'cpo', name: 'Vattenfall', countries: ['nl'] },
    { uuid: 'a062dc4a-fbb6-4036-8927-792be60ae09a', type: 'cpo', name: 'Equans', countries: ['nl'] },
    { uuid: '33967046-5e84-4db4-aa2a-27971b354ceb', type: 'cpo', name: 'OPCHARGE', countries: ['nl'] },
    { uuid: '87feb792-a3e7-4182-ac58-ef532527e07d', type: 'cpo', name: 'Park&Charge', countries: ['nl'] },
    { uuid: 'e4a9e585-7249-45e8-be1d-85a0da47a903', type: 'cpo', name: 'Unica', countries: ['nl'] },
    { uuid: '0aff4995-66af-4f44-a25e-878b9e009b3b', type: 'cpo', name: 'Baas BV - EVReady', countries: ['nl'] },
    { uuid: '828167a6-02ef-4919-8df2-52118662b6ee', type: 'cpo', name: 'Awesems', countries: ['nl'] },
    { uuid: '46dd3b7c-de0c-47fd-9312-c629027f045c', type: 'cpo', name: 'Joulz', countries: ['nl'] },
    { uuid: 'b49e8192-9b6d-45e7-aeb2-06dbacc3b51d', type: 'cpo', name: 'Mick Energy', countries: ['nl'] },
    { uuid: '183a5669-7e43-4a23-b58c-8b081bb2c490', type: 'cpo', name: 'Green Cities Energy', countries: ['nl'] },
    { uuid: 'd3cad164-c1c1-46db-8838-ff2905167623', type: 'cpo', name: 'Allego', countries: ['nl', 'be'] },
    { uuid: 'e6a13c70-975c-4416-a143-6bcc269afd34', type: 'cpo', name: 'TotalEnergies', countries: ['nl', 'be'] },
    { uuid: 'ae33b0a0-1d6d-44e9-88b3-cc398d5b93e2', type: 'cpo', name: 'Bluecorner', countries: ['be'] },
    { uuid: '3d555142-f1a7-435c-958f-f3135b955b3b', type: 'cpo', name: 'Engie', countries: ['be'] },
    { uuid: 'e47959cb-a3bf-4794-950f-5c9b6b247b4a', type: 'cpo', name: 'Dutch Charge', countries: ['nl'] },
    { uuid: '49e3ce21-38b4-49fc-a7ef-a580c616a770', type: 'cpo', name: 'City Charging', countries: ['nl'] },
    { uuid: 'ab10104d-31df-4c42-9202-3f5920abdc3c', type: 'cpo', name: 'Den Hartog Charging', countries: ['nl'] },
    { uuid: 'cfade7d4-39eb-4679-b91b-7bf2da851130', type: 'cpo', name: 'Orange Charging', countries: ['nl'] },
    { uuid: 'ae0c96ef-01b2-4764-ab3b-d8e51bcd7532', type: 'cpo', name: 'PowerGo', countries: ['nl'] },
    { uuid: '795520a2-74f9-4177-b97c-7e923e978198', type: 'cpo', name: 'Agrisnellaad', countries: ['nl'] },
    { uuid: '5f485fce-c137-4e85-b275-e8458b0b74dc', type: 'cpo', name: 'EVBox', countries: ['nl', 'be'] },
    { uuid: '818bccdf-1d5e-4340-83b6-35d390d34506', type: 'cpo', name: 'Shell Recharge', countries: ['nl', 'be'] },
    { uuid: '56a82da9-dcdc-4553-9dd4-e0364497599f', type: 'cpo', name: 'Aers Energy', countries: ['nl'] },
  ],
  getCpoById: (cpos, uuid) => {
    const cpo = cpos.find(cpo => cpo.uuid === uuid)

    if (typeof cpo === 'undefined') {
      return ''
    }

    return cpo
  },
}
