<template>
  <div class="ParticipationPanel d-flex">
    <aside class="ParticipationPanel__Aside flex-shrink-0 mr-3">
      <h3 class="ParticipationPanel__Title">
        {{ $t('participationPanel.title') }}
      </h3>
      <div class="w-75 mt-3">
        <p>
          {{ $t('participationPanel.description') }}
        </p>
      </div>
    </aside>
    <div class="ParticipationPanel__Main flex-grow-1 flex-shrink-1">
      <div class="d-flex align-items-center">
        <span class="ParticipationPanel__Label mr-3">
          {{ $t('participationPanel.options.participation.title') }}
        </span>

        <ToggleSwitch
          v-model="data.enabled"
          class="mb-0"
        />
        <span class="flex-grow-1 ml-3 text-muted" />
        <SvgIcon
          id="participatieEnabled"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="participatieEnabled"
          triggers="hover"
        >
          {{ $t('participationPanel.options.participation.tooltip') }}
        </b-tooltip>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="ParticipationPanel__Label mr-3">
          {{ $t('participationPanel.options.interaction.title') }}
        </span>

        <ToggleSwitch
          v-model="data.interactionEnabled"
          :disabled="!data.enabled"
          class="mb-0"
        />
        <span class="flex-grow-1 ml-3 text-muted" />
        <SvgIcon
          id="interactionEnabled"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="interactionEnabled"
          triggers="hover"
        >
          <p class="mb-1">
            {{ $t('participationPanel.options.interaction.tooltip1') }}
            {{ participationLink }}.
          </p>

          {{ $t('participationPanel.options.interaction.tooltip2') }}
          {{ viewerLink }}.
        </b-tooltip>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="ParticipationPanel__Label mr-3">
          {{ $t('participationPanel.options.interactionPastParticipations.title') }}
        </span>

        <ToggleSwitch
          v-model="data.interactionOnPastParticipations"
          :disabled="!data.interactionEnabled"
          class="mb-0"
        />
        <span class="flex-grow-1 ml-3 text-muted" />
        <SvgIcon
          id="interactionOnPastParticipations"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="interactionOnPastParticipations"
          triggers="hover"
        >
          {{ $t('participationPanel.options.interactionPastParticipations.tooltip') }}
        </b-tooltip>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="ParticipationPanel__Label mr-3">
          {{ $t('participationPanel.options.citizenSuggestions.title') }}
        </span>

        <ToggleSwitch
          v-model="data.suggestionsEnabled"
          :disabled="!data.interactionEnabled"
          class="mb-0"
        />
        <span class="flex-grow-1 ml-3 text-muted" />
        <SvgIcon
          id="suggestionsEnabled"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="suggestionsEnabled"
          triggers="hover"
        >
          {{ $t('participationPanel.options.citizenSuggestions.tooltip') }}
        </b-tooltip>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="ParticipationPanel__Label mr-3">
          {{ $t('participationPanel.options.legendToggleSTD.title') }}
        </span>

        <ToggleSwitch
          v-model="data.defaultLayerVisibility.suggestion"
          class="mb-0"
        />
        <span class="flex-grow-1 ml-3 text-muted" />
        <SvgIcon
          id="defaultLayerVisibilitySuggestions"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="defaultLayerVisibilitySuggestions"
          triggers="hover"
        >
          {{ $t('participationPanel.options.legendToggleSTD.tooltip') }}
        </b-tooltip>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="ParticipationPanel__Label mr-3">
          {{ $t('participationPanel.options.link') }}
        </span>
        <div class="flex-grow-1 mr-5">
          <b-input-group :prepend="`${participationUrl}/`">
            <b-form-input
              v-model.trim="data.slug"
              :state="data.enabled ? isUniqueSlug : null"
              :disabled="!data.enabled"
            />
          </b-input-group>
        </div>
      </div>

      <div class="ParticipationPanel__Divider" />

      <div class="d-flex align-items-center mt-4">
        <span class="ParticipationPanel__Label mr-3">
          {{ $t('participationPanel.options.dateOpen.title') }}
        </span>
        <b-input
          v-model="data.openDate"
          :disabled="!data.enabled"
          class="flex-grow-1 mr-3"
          type="date"
        />
        <SvgIcon
          id="openDate"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="openDate"
          triggers="hover"
        >
          {{ $t('participationPanel.options.dateOpen.tooltip') }}
        </b-tooltip>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="ParticipationPanel__Label mr-3">
          {{ $t('participationPanel.options.dateClose.title') }}
        </span>
        <b-input
          v-model="data.closeDate"
          class="flex-grow-1 mr-3"
          type="date"
          :state="data.enabled ? isCloseDateAfterOpeningDate : null"
          :disabled="!data.enabled"
        />
        <SvgIcon
          id="closeDate"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="closeDate"
          triggers="hover"
        >
          {{ $t('participationPanel.options.dateClose.tooltip') }}
        </b-tooltip>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="ParticipationPanel__Label mr-3">
          {{ $t('participationPanel.options.preview.title') }}
        </span>

        <ToggleSwitch
          v-model="data.previewEnabled"
          :disabled="!data.enabled"
          class="mb-0"
        />
        <a
          v-if="data.previewEnabled"
          :href="`https://${participationUrl}/${data.slug}?preview=${data.previewCode}`"
          target="_blank"
          class="ml-3 text-muted"
          v-text="
            `${participationUrl}/${data.slug}?preview=${data.previewCode}`
          "
        />
        <span class="flex-grow-1 ml-3 text-sm text-muted" />
        <SvgIcon
          id="previewEnabled"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="previewEnabled"
          triggers="hover"
        >
          {{ $t('participationPanel.options.preview.tooltip') }}
        </b-tooltip>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="ParticipationPanel__Label mr-3">
          {{ $t('participationPanel.options.msgPostClose.title') }}
        </span>

        <ToggleSwitch
          v-model="data.showMessageAfterClose"
          :disabled="!data.enabled"
          class="mb-0"
        />
        <span class="flex-grow-1 ml-3 text-muted" />
        <SvgIcon
          id="showMessageAfterClose"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="showMessageAfterClose"
          triggers="hover"
        >
          {{ $t('participationPanel.options.msgPostClose.tooltip') }}
        </b-tooltip>
      </div>

      <div class="d-flex mt-4">
        <div class="ParticipationPanel__Label mr-3 mt-5">
          <span> {{ $t('participationPanel.textClosingMessage.title') }} </span>
        </div>
        <!--eslint-enable-->
        <b-textarea
          v-model.trim="data.closingMessage"
          :state="data.enabled ? validateHtml(data.closingMessage) : null"
          :disabled="!data.enabled"
          class="flex-grow-1 mr-3"
          rows="6"
        />
        <SvgIcon
          id="closingMessageText"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="closingMessageText"
          triggers="hover"
        >
          {{ $t('participationPanel.textClosingMessage.tooltip') }}
        </b-tooltip>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="ParticipationPanel__Label mr-3">
          {{ $t('participationPanel.options.logoBG.title') }}
        </span>
        <b-input
          v-model="data.logoBackgroundColor"
          class="flex-grow-1 mr-3"
          type="color"
          :disabled="!data.enabled"
        />
        <SvgIcon
          id="logoBackgroundColor"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="logoBackgroundColor"
          triggers="hover"
        >
          {{ $t('participationPanel.options.logoBG.tooltip') }}
        </b-tooltip>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="ParticipationPanel__Label mr-3">
          {{ $t('participationPanel.options.showPolicy.title') }}
        </span>

        <ToggleSwitch
          v-model="data.showPolicy"
          :disabled="!data.enabled"
          class="mb-0"
        />
        <span class="flex-grow-1 ml-3 text-muted" />
        <SvgIcon
          id="showPolicy"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="showPolicy"
          triggers="hover"
        >
          {{ $t('participationPanel.options.showPolicy.tooltip') }}
        </b-tooltip>
      </div>

      <div class="d-flex mt-4">
        <div class="ParticipationPanel__Label mr-3 mt-5">
          <span> {{ $t('participationPanel.defaultPolicy.title') }} </span>
          <b-button
            :disabled="!data.enabled"
            class="mt-1"
            @click="previewPolicyText = !previewPolicyText"
            v-text="
              previewPolicyText
                ? $t('participationPanel.change')
                : $t('participationPanel.preview')
            "
          />
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="previewPolicyText"
          class="ParticipationPanel__HtmlPreview flex-grow-1 mr-3"
          v-html="data.policyText"
        />
        <!--eslint-enable-->
        <b-textarea
          v-else
          v-model.trim="data.policyText"
          :state="data.enabled ? validateHtml(data.policyText) : null"
          :disabled="!data.enabled"
          class="flex-grow-1 mr-3"
          rows="6"
        />
        <SvgIcon
          id="policyText"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="policyText"
          triggers="hover"
        >
          {{ $t('participationPanel.defaultPolicy.tooltip') }}
        </b-tooltip>
      </div>

      <div class="ParticipationPanel__Divider" />

      <div class="d-flex align-items-center mt-4">
        <span class="ParticipationPanel__Label mr-3">
          {{ $t('participationPanel.options.statusStations.title') }}

          <SelectStatusesModal
            :preselected="statuses"
            @select="handleStatusSelect"
          />
        </span>

        <vSelect
          v-model="statuses"
          :disabled="true"
          class="flex-grow-1 mr-3"
          multiple
        />
        <SvgIcon
          id="statuses"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="statuses"
          triggers="hover"
        >
          {{ $t('participationPanel.options.statusStations.tooltip') }}
        </b-tooltip>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="ParticipationPanel__Label mr-3">
          {{ $t('participationPanel.options.phases.title') }}
        </span>
        <vSelect
          v-model="selectedPhases"
          :options="phaseOptions"
          :disabled="!data.enabled"
          :searchable="false"
          :select-group-label="$t('components.form.select.select-groupLabels')"
          class="flex-grow-1 mr-3"
          multiple
        />
        <SvgIcon
          id="fases"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="fases"
          triggers="hover"
        >
          {{ $t('participationPanel.options.phases.tooltip') }}
        </b-tooltip>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="ParticipationPanel__Label mr-3">
          {{ $t('participationPanel.options.cpos.title') }}
        </span>
        <vSelect
          v-model="cpos"
          :options="cpoOptions"
          :disabled="!data.enabled"
          class="flex-grow-1 mr-3"
          multiple
        />
        <SvgIcon
          id="cpos"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="cpos"
          triggers="hover"
        >
          {{ $t('participationPanel.options.cpos.tooltip') }}
        </b-tooltip>
      </div>

      <div class="d-flex mt-4">
        <div class="ParticipationPanel__Label mr-3 mt-5">
          <span>{{ $t('participationPanel.defaultIntro.title') }}</span>
          <b-button
            :disabled="!data.enabled"
            class="mt-1"
            @click="previewIntroductionText = !previewIntroductionText"
            v-text="
              previewIntroductionText
                ? $t('participationPanel.change')
                : $t('participationPanel.preview')
            "
          />
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="previewIntroductionText"
          class="ParticipationPanel__HtmlPreview flex-grow-1 mr-3"
          v-html="data.introductionText"
        />
        <!--eslint-enable-->
        <b-textarea
          v-else
          v-model.trim="data.introductionText"
          :state="data.enabled ? validateHtml(data.introductionText) : null"
          :disabled="!data.enabled"
          class="flex-grow-1 mr-3"
          rows="6"
        />
        <SvgIcon
          id="introductionText"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="introductionText"
          triggers="hover"
        >
          {{ $t('participationPanel.defaultIntro.tooltip') }}
        </b-tooltip>
      </div>

      <div v-if="!data.interactionEnabled">
        <div
          v-for="(cpo, cpoIndex) in data.cpos"
          :key="`cpo-${cpoIndex}`"
          class="d-flex mt-4"
        >
          <div
            class="ParticipationPanel__Label mr-3 mt-4 d-flex flex-column align-items-start"
          >
            <div v-if="!!cpo.label">
              {{ $t('participationPanel.defaultSidebar.title') }}
              {{ cpo.label }}
            </div>
            <span v-else>{{
              $t('participationPanel.defaultSidebar.title') }}
            </span>
            <b-button
              :disabled="!data.enabled"
              class="mt-1"
              @click="
                activePreviewSidebarText =
                  activePreviewSidebarText !== cpoIndex ? cpoIndex : -1
              "
              v-text="
                activePreviewSidebarText === cpoIndex
                  ? $t('participationPanel.change')
                  : $t('participationPanel.preview')
              "
            />
          </div>
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="activePreviewSidebarText === cpoIndex"
            class="ParticipationPanel__HtmlPreview flex-grow-1 mr-3"
            v-html="cpo.sidebarText"
          />
          <!--eslint-enable-->
          <b-textarea
            v-else
            v-model.trim="cpo.sidebarText"
            :state="data.enabled ? validateHtml(cpo.sidebarText) : null"
            :disabled="!data.enabled"
            class="flex-grow-1 mr-3"
            rows="4"
          />
          <SvgIcon
            :id="`sidebarText-${cpoIndex}`"
            icon="info-circle-regular"
          />
          <b-tooltip
            :target="`sidebarText-${cpoIndex}`"
            triggers="hover"
          >
            {{ $t('participationPanel.defaultSidebar.tooltip') }}
          </b-tooltip>
        </div>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="ParticipationPanel__Label mr-3">
          {{ $t('participationPanel.options.geography.title') }}
        </span>
        <b-textarea
          v-model.trim="data.geography"
          :state="data.enabled ? geographyIsValid : null"
          :disabled="!data.enabled"
          :rows="data.geography ? 4 : 2"
          class="flex-grow-1 mr-3"
          @blur="beautifyGeography"
        />
        <SvgIcon
          id="geography"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="geography"
          triggers="hover"
        >
          {{ $t('participationPanel.options.geography.tooltip') }}
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import geojsonhint from 'geojsonhint'
import ToggleSwitch from '@/components/form/ToggleSwitch'
import vSelect from '@/components/form/vSelect'
import SvgIcon from '@/components/common/SvgIcon'
import chargingpointCpoMixin from '@/mixins/chargingpoint/chargingpointCpoMixin'
import { statusesWithLabelDutch, statusesWithLabelEnglish } from '@/../shared/services/statusTranslations'
import SelectStatusesModal from '@/components/admin/municipalities/SelectStatusesModal.vue'

export default {
  name: 'ParticipationPanel',
  components: { SelectStatusesModal, SvgIcon, vSelect, ToggleSwitch },
  mixins: [chargingpointCpoMixin],
  props: {
    values: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      data: {},
      previewIntroductionText: false,
      previewPolicyText: false,
      activePreviewSidebarText: -1,
      trackChanges: true,
      participationLink: process.env.VUE_APP_PARTICIPATION_URL,
      viewerLink: process.env.VUE_APP_VIEWER_URL,
    }
  },
  computed: {
    ...mapGetters('config', [
      'config',
      'getAllParticipationConfigs',
      'getPhases',
    ]),
    participationUrl() {
      return this.isInteractionEnabled
        ? this.participationLink
        : this.viewerLink
    },
    isUniqueSlug() {
      if (!this.data.slug) {
        return false
      }

      const participationSlugs = this.getAllParticipationConfigs
        .filter(
          (participationConfig) =>
            participationConfig && participationConfig.uuid !== this.data.uuid,
        )
        .map(({ slug }) => slug)
        .filter((slug) => !!slug)

      return !participationSlugs.includes(this.data.slug)
    },
    isInteractionEnabled() {
      const [participation] = this.config.participation
      return participation.interactionEnabled
    },
    isCloseDateAfterOpeningDate() {
      if (!this.data.openDate || !this.data.closeDate) {
        return null
      }

      if (new Date(this.data.closeDate) >= new Date(this.data.openDate)) {
        return null
      }

      return false
    },
    activePhases() {
      const oldPhases = [
        { value: 1, label: 'Fase 1' },
        { value: 2, label: 'Fase 2' },
      ]
      const newActivePhases =
        this.config.phases
          ?.filter(({ active }) => !!active)
          .map(({ uuid, label }) => ({ value: uuid, label })) ?? []
      return [...oldPhases, ...newActivePhases]
    },
    phaseOptions() {
      // Only show the phases that are not yet selected
      return this.activePhases.filter(
        ({ value }) => !this.data.phases.includes(value),
      )
    },
    selectedPhases: {
      get() {
        return this.activePhases.filter(({ value }) =>
          this.data.phases.includes(value),
        )
      },
      set(newPhases) {
        this.data.phases = newPhases.map(({ value }) => value)
      },
    },
    cpos: {
      get() {
        return this.cpoOptions.filter(({ value }) =>
          this.data.cpos.some((cpo) => cpo.value === value),
        )
      },
      set(newCpos) {
        const [firstParticipationConfig] = this.values
        newCpos.map((newcpo) => {
          const existingCpo = this.data.cpos.find(
            (cpo) => cpo.value === newcpo.value,
          )
          if (existingCpo) {
            newcpo.sidebarText = existingCpo.sidebarText
            return newcpo
          } else {
            newcpo.sidebarText = firstParticipationConfig.sidebarText || ''
          }
        })

        const cpos = newCpos.length
          ? newCpos
          : [{ value: 'default', sidebarText: this.data.sidebarText }]
        this.data.cpos = cpos.sort((a, b) => a.label.localeCompare(b.label))
      },
    },
    cpoOptions() {
      return this.cpoSelectOptions.map(({ value, text }) => ({
        value,
        label: text,
      }))
    },
    statuses: {
      get() {
        return this.statusOptions.filter(({ value }) =>
          this.data.statuses.includes(value),
        )
      },
      set(newStatuses) {
        this.data.statuses = newStatuses.map(({ value }) => value)
      },
    },
    statusOptions() {
      const statusesLang = localStorage.getItem('language') === 'nl' ? statusesWithLabelDutch : statusesWithLabelEnglish

      return Object.entries(statusesLang).map(([value, label]) => ({ value, label }))
    },
    geographyIsValid() {
      if (!this.data.geography) {
        return null
      }

      return geojsonhint.hint(this.data.geography).length === 0
    },
  },
  watch: {
    /**
     * Values passed via props override the data, without signaling input changes
     */
    values: {
      immediate: true,
      handler() {
        this.setValues()
      },
    },
    /**
     * When any input changes, pass it on
     */
    data: {
      handler(data) {
        if (this.trackChanges) {
          const [, ...otherParticipationConfigs] = this.values
          this.$emit('input', [data, ...otherParticipationConfigs])
        }
      },
      deep: true,
    },
  },
  methods: {
    validateHtml(text) {
      if (!text) {
        return null
      }

      const parser = new DOMParser()
      const doc = parser.parseFromString(`<div>${text}</div>`, 'text/xml')
      return !doc.documentElement.querySelector('parsererror')
    },
    beautifyGeography() {
      if (!this.geographyIsValid) {
        return
      }

      this.data.geography = JSON.stringify(
        JSON.parse(this.data.geography),
        null,
        2,
      )
    },
    /**
     * Set prop values as new data, without signaling input changes
     */
    setValues() {
      this.trackChanges = false

      const [firstParticipationConfig] = this.values

      // Transform cpos from plain uuid's array to array of objects if needed, including sidebarText prop //
      let newCpos = []
      if (!firstParticipationConfig.cpos.length) {
        firstParticipationConfig.cpos.push({
          value: 'default',
          sidebarText: firstParticipationConfig.sidebarText,
        })
      } else if (firstParticipationConfig.cpos.some((cpo) => !cpo.value)) {
        // uuid's only (old array)
        firstParticipationConfig.cpos.map((cpoUuid) => {
          const cpo = this.cpoOptions.find(
            (operator) => operator.value === cpoUuid,
          )

          if (cpo) {
            newCpos.push({
              value: cpo.value,
              label: cpo.label,
              sidebarText: firstParticipationConfig.sidebarText || '',
            })
          }
        })
        firstParticipationConfig.cpos = newCpos
      }

      firstParticipationConfig.cpos.sort((a, b) =>
        a.label.localeCompare(b.label),
      )
      this.data = firstParticipationConfig

      this.$nextTick(() => {
        this.trackChanges = true
      })
    },
    handleStatusSelect({ selected }) {
      this.statuses = selected
    },
  },
}
</script>

<style lang="scss">
.ParticipationPanel {
  &__Aside {
    width: 200px;

    @media (min-width: 1280px) {
      width: 275px;
    }
  }
  &__Main {
    font-size: 1.25rem;

    .form-control {
      width: 100px;
      font-size: 1.25rem;
    }
  }
  &__Label {
    flex-shrink: 0;
    width: 175px;
  }
  .SvgIcon {
    flex-shrink: 0;
    font-size: 1.75rem;
  }

  &__Divider {
    border-top: solid 1px lightgray;
    margin: 1.5rem 2rem 0 2rem;
  }

  &__HtmlPreview {
    background-color: var(--grey);
    padding: 1rem 1.5rem;
    border-radius: 0.25rem;
    width: 1px;

    a {
      text-decoration: underline;
    }
  }
}
</style>
