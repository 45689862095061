import { render, staticRenderFns } from "./LocationStatusSelector.vue?vue&type=template&id=636d1a1b&"
import script from "./LocationStatusSelector.vue?vue&type=script&lang=js&"
export * from "./LocationStatusSelector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports