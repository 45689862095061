<template>
  <div
    v-if="!isLoadingFauna"
    class="Pages w-100 h-100 py-5 mh-100"
  >
    <FactsheetControls
      :pdf-title="`Factsheet participatie - ${municipalityLabel} - EVtools.pdf`"
      :download-disabled="!hasFactsheetData"
      class="d-flex"
    >
      <template #right>
        <span
          v-if="comments.length || hasSelectedPeriod"
          class="PrintBtn m-3 ml-auto d-flex align-items-center"
        >
          <h5 class="m-0">{{ $t('components.form.dropdown.selectPeriod') }}</h5>
          <div class="d-flex h-100">
            <b-form-datepicker
              id="startDate"
              v-model="startDateValue"
              :max="currentCloseDate"
              :locale="$i18n.locale"
              :placeholder="$t('from')"
              class="d-flex align-items-center datepicker"
            />
          </div>
          <span>{{ $t('to') }}</span>
          <div class="d-flex h-100">
            <b-form-datepicker
              id="endDate"
              v-model="endDateValue"
              :max="currentCloseDate"
              :locale="$i18n.locale"
              class="d-flex align-items-center datepicker"
            />
            <!--:min="startDateValue"-->
          </div>
          <b-button
            variant="outline-primary h-100"
            class="PrintBtn all-reactions"
            @click="showAllComments"
          >
            {{ $t('components.form.dropdown.allPeriods') }}
          </b-button>
        </span>
        <b-button
          :disabled="!comments.length"
          variant="outline-primary"
          class="PrintBtn m-3 ml-auto excel"
          @click="downloadExcel"
        >
          <BIconCloudArrowDownFill class="mr-1" />
          Download Excel
        </b-button>
      </template>
    </FactsheetControls>

    <FactsheetPage
      v-if="isParticipationFactsheetEnabled && hasFactsheetData"
      ref="factsheet"
      :landscape="true"
      :marginless="true"
      :title="
        $t('components.factsheets.participation.title', {
          municipality: municipalityLabel,
        })
      "
      :timestamp="timestamp"
    >
      <!-- DEEL 1 -->
      <FactsheetSection
        :title="$t('components.factsheets.participation.part1.title')"
        icon="factsheet/message.png"
      >
        <div
          class="Circle pt-3 pb-3"
          style="width: 130px"
        >
          <div class="Circle">
            <span v-text="totalComments" />
          </div>
          <div># reacties</div>
        </div>
        <div class="d-flex">
          <DonutChart
            :data="commentTypes"
            title="Verdeling type reacties"
          />
          <div class="d-flex flex-column justify-content-center">
            <div
              v-for="typeOfComment in commentTypes"
              :key="typeOfComment.key"
              class="Factsheet__Donutchart"
            >
              <div
                class="Factsheet__Donutchart__Color"
                :style="`background: ${typeOfComment.color}`"
              />
              <div
                class="pl-2"
                v-text="typeOfComment.label"
              />
            </div>
          </div>
        </div>
        <div class="Factsheet__Text">
          <div>
            {{
              $t("components.factsheets.participation.part1.description", {
                start: periodDateFormat({ dateType: 'startDateValue' }) || startDate,
                end: periodDateFormat({ dateType: 'endDateValue' }) || endDate,
                comments: comments.length,
                uniqueCount: uniqueChargingpointsCounts,
              })
            }}
          </div>
        </div>
      </FactsheetSection>

      <!-- DEEL 2 -->
      <FactsheetSection
        :title="$t('components.factsheets.participation.part2.title')"
        icon="factsheet/calendar.png"
      >
        <div class="mb-2">
          <ColumnChart
            :data="commentsPerWeek"
            title="Aantal reacties per week"
            :annotation="
              $t('components.factsheets.participation.part2.annotationWeek')
            "
          />
        </div>
        <div class="mb-2">
          <ColumnChart
            :data="commentsPerWeekday"
            title="Aantal reacties per weekdag"
            :annotation="
              $t('components.factsheets.participation.part2.annotationDay')
            "
          />
        </div>
        <div class="Factsheet__Text">
          <p>
            {{ $t("components.factsheets.participation.part2.description1") }}
          </p>
          <p>
            {{ $t("components.factsheets.participation.part2.description2") }}
          </p>
        </div>
      </FactsheetSection>

      <!-- DEEL 3 -->
      <FactsheetSection
        :title="$t('components.factsheets.participation.part3.title')"
        icon="factsheet/check.png"
      >
        <div style="width: 130px" />
        <div class="d-flex mt-3">
          <DonutChart
            :data="commentStatuses"
            title="Verdeling statusreacties"
          />
          <div class="d-flex flex-column justify-content-center mt-3">
            <div
              v-for="commentStatus in commentStatuses"
              :key="commentStatus.key"
              class="Factsheet__Donutchart"
            >
              <div
                class="Factsheet__Donutchart__Color"
                :style="`background: ${commentStatus.color}`"
              />
              <div
                class="pl-2"
                v-text="commentStatus.label"
              />
            </div>
          </div>
        </div>
        <div class="Factsheet__Text">
          <div>
            {{
              $t("components.factsheets.participation.part3.description", {
                total: totalComments,
                finished: finishedCommentsCount,
                open: totalComments - finishedCommentsCount,
              })
            }}
          </div>
        </div>
      </FactsheetSection>
    </FactsheetPage>
    <FactsheetPage
      v-else
      :landscape="true"
    >
      <h1
        class="text-center pt-5"
      >
        {{ noCommentsMessage }}
      </h1>
    </FactsheetPage>
  </div>
  <div
    v-else
    class="w-100 d-flex align-items-center justify-content-center"
  >
    <b-spinner
      class="mr-3"
      :label="$t('loading')"
    />
    <span>{{ $t("loading") }}</span>
  </div>
</template>

<script>
import { image } from '@/helpers/assets'
import { getDateStamp } from '@/helpers/date'

import FactsheetPage from '@/components/factsheet/FactsheetPage'
import FactsheetControls from '@/components/factsheet/FactsheetControls'
import FactsheetSection from '@/components/factsheet/FactsheetSection'
import DonutChart from '@/components/factsheet/participation/DonutChart'
import ColumnChart from '@/components/factsheet/participation/ColumnChart'

import { saveAs } from 'file-saver'
import { BIconCloudArrowDownFill } from 'bootstrap-vue'
import dayjs from 'dayjs'
const weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)

import { labelByCode } from '@/services/municipalities'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import chargingpointsLoadMixin from '@/mixins/chargingpoint/chargingpointsLoadMixin'
import getCssProperty from '@/helpers/cssProperty'

export default {
  components: {
    FactsheetPage,
    FactsheetControls,
    FactsheetSection,
    DonutChart,
    ColumnChart,
    BIconCloudArrowDownFill,
  },
  mixins: [chargingpointsLoadMixin],
  data() {
    return {
      isLoadingFauna: false,
      startDateValue: null,
      endDateValue: null,
    }
  },
  computed: {
    ...mapGetters('config', ['isParticipationEnabled', 'participationConfigs']),
    ...mapGetters('access', ['hasAdminAccess', 'getActiveMunicipality']),
    ...mapGetters('planmode', [
      'getChargingPointByUuid',
      'getParticipationComments',
    ]),
    municipalityLabel() {
      return labelByCode({ code: this.getActiveMunicipality })
    },
    timestamp() {
      return new Date().toLocaleDateString('nl', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
    comments() {
      return this.getParticipationComments.filter(this.filteredComments)
    },
    participationConfig() {
      return this.participationConfigs.length
        ? this.participationConfigs[0]
        : {}
    },
    isParticipationFactsheetEnabled() {
      const superuser = this.$auth.user?.['https://evmaps.nl/superuser']
      const admin = this.hasAdminAccess({ code: this.getActiveMunicipality })
      return this.isParticipationEnabled || superuser || admin
    },
    hasFactsheetData() {
      return !!this.comments.length
    },
    totalComments() {
      return this.comments.length
    },
    startDate() {
      const { openDate } = this.participationConfig
      const firstComment = this.comments[0]
      const startDate = openDate ?? firstComment.data.created_at
      return this.formatDate({ date: startDate, short: true })
    },
    endDate() {
      const { closeDate } = this.participationConfig
      const lastComment = this.comments[this.comments.length - 1]
      const endDate = closeDate ?? lastComment.data.created_at
      return this.formatDate({ date: endDate, short: true })
    },
    noCommentsMessage () {
      const messageType = this.hasSelectedPeriod
        ? 'noReactionsWithinPeriod'
        : !this.isParticipationEnabled
          ? 'notActivated'
          : 'notAvailable'

      return this.$t(`components.factsheets.participation.${messageType}`, {
              municipality: this.municipalityLabel,
            })
    },
    currentCloseDate () {
      return this.participationConfig?.closeDate
    },
    uniqueChargingpointsCounts() {
      return this.comments
        .map(({ data }) => data.chargingpointUuid)
        .filter((uuid, index, self) => self.indexOf(uuid) === index).length
    },
    commentTypes() {
      const newComments = this.comments.filter(
        ({ data }) => !data.sentiment,
      ).length
      const positiveComments = this.comments.filter(
        ({ data }) => data.sentiment === 1,
      ).length
      const negativeComments = this.comments.filter(
        ({ data }) => data.sentiment === -1,
      ).length
      const totalComments = newComments + positiveComments + negativeComments
      return [
        {
          key: 'new',
          value: newComments / totalComments,
          label: this.$i18n.t('components.factsheets.participation.part1.newLabel', { new: newComments }),
          color: getCssProperty('success'),
        },
        {
          key: 'positive',
          value: positiveComments / totalComments,
          label: this.$i18n.t('components.factsheets.participation.part1.positiveLabel', { positive: positiveComments }),
          color: getCssProperty('secondary'),
        },
        {
          key: 'negative',
          value: negativeComments / totalComments,
          label: this.$i18n.t('components.factsheets.participation.part1.negativeLabel', { negative: negativeComments }),
          color: getCssProperty('primary'),
        },
      ]
    },
    commentStatuses() {
      const newComments = this.comments.filter(
        ({ data }) => data.status === 'new',
      ).length
      const todoComments = this.comments.filter(
        ({ data }) => data.status === 'todo',
      ).length
      const completedComments = this.comments.filter(
        ({ data }) => data.status === 'done',
      ).length
      const ignoredComments = this.comments.filter(
        ({ data }) => data.status === 'ignore',
      ).length
      const totalComments =
        newComments + todoComments + completedComments + ignoredComments

      return [
        {
          key: 'new',
          value: newComments / totalComments,
          label: this.$i18n.t('components.factsheets.participation.part3.newLabel', { new: newComments }),
          color: getCssProperty('success'),
        },
        {
          key: 'todo',
          value: todoComments / totalComments,
          label: this.$i18n.t('components.factsheets.participation.part3.todoLabel', { todo: todoComments }),
          color: '#285943',
        },
        {
          key: 'done',
          value: completedComments / totalComments,
          label: this.$i18n.t('components.factsheets.participation.part3.doneLabel', { done: completedComments }),
          color: getCssProperty('secondary'),
        },
        {
          key: 'ignore',
          value: ignoredComments / totalComments,
          label: this.$i18n.t('components.factsheets.participation.part3.ignoreLabel', { ignore: ignoredComments }),
          color: getCssProperty('primary'),
        },
      ]
    },
    finishedCommentsCount() {
      return this.comments.filter(({ data }) =>
        ['done', 'ignore'].includes(data.status),
      ).length
    },
    commentsPerWeek() {
      const commentsPerWeek = this.comments.reduce((total, comment) => {
        const weekNumber = dayjs(comment.data.created_at).week()
        return {
          ...total,
          [weekNumber]: total[weekNumber] ? total[weekNumber] + 1 : 1,
        }
      }, {})

      return Object.keys(commentsPerWeek).map((weekNumber) => [
        `Week ${weekNumber}`,
        commentsPerWeek[weekNumber],
      ])
    },
    commentsPerWeekday() {
      const commentsPerWeekday = this.comments.reduce(
        (total, comment) => {
          const weekday = dayjs(comment.data.created_at).day()
          total[weekday] = total[weekday] + 1
          return total
        },
        [...Array(7).fill(0)],
      )

      const weekdays = ['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo']
      commentsPerWeekday.push(commentsPerWeekday.shift()) // Shift Sunday to the end of the week
      return commentsPerWeekday.map((count, index) => [weekdays[index], count])
    },
    hasSelectedPeriod () {
      return (!!this.startDateValue && !!this.endDateValue)
    },
  },
  watch: {
    getActiveMunicipality: {
      immediate: true,
      handler: 'fetchFactsheetData',
    },
  },
  methods: {
    ...mapMutations('app', ['setAppReady']),
    ...mapActions('planmode', ['fetchAllParticipationComments']),
    image,
    formatDate ({ date, short }) {
      const locale = { en: 'en-EN', nl: 'nl-NL' } // For date translation
      const options = short
        ? { month: 'long', day: 'numeric', year: 'numeric' }
        : { day: 'numeric', weekday: 'long', month: 'short', year: 'numeric' }

      return new Date(date).toLocaleDateString(locale[this.$i18n.locale], options)
    },
    periodDateFormat ({ dateType }) {
      const date = this[dateType]
      return date && this.formatDate({ date, short: true })
    },
    showAllComments () {
      this.startDateValue = null
      this.endDateValue = this.participationConfig.closeDate
    },
    async fetchFactsheetData() {
      this.showAllComments()

      if (!this.isParticipationEnabled) return
      this.isLoadingFauna = true

      try {
        // Fetch the chargingpoints
        await this.$_chargingpointsLoadMixin_loadChargingPoints({
          code: this.getActiveMunicipality,
        })

        // Fetch the participation comments
        await this.fetchAllParticipationComments({
          code: this.getActiveMunicipality,
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Error: ', e)
      }

      this.setAppReady({ value: true })
      this.isLoadingFauna = false
    },
    formatSentiment(sentiment) {
      if (sentiment === 1) {
        return 'Positief'
      }
      if (sentiment === -1) {
        return 'Negatief'
      }
      return 'Voorgestelde locatie'
    },
    setWorksheetStyle(worksheet) {
      return worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.height = 30
        row.eachCell((cell) => {
          cell.alignment = {
            indent: 1,
            vertical: 'middle',
          }

          if (rowNumber === 1) {
            cell.font = {
              size: 12,
              bold: true,
            }
          }
        })
      })
    },
    async downloadExcel() {
      const { default: ExcelJS } = await import('exceljs') // Dynamically import exceljs
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Participatie reacties')
      worksheet.columns = [
        { header: 'UUID', key: 'uuid', width: 40 },
        { header: 'Aangemaakt', key: 'created_at', width: 14 },
        { header: 'Laatst geüpdate', key: 'updated_at', width: 14 },
        { header: 'Locatie ID', key: 'charginpoint_id', width: 14 },
        { header: 'Straatnaam', key: 'street', width: 32 },
        { header: 'Huisnummer', key: 'streetnumber', width: 10 },
        { header: 'Toevoeging', key: 'streetsuffix', width: 12 },
        { header: 'Postcode', key: 'postalcode', width: 12 },
        { header: 'Afhandelstatus', key: 'status', width: 16 },
        { header: 'Sentiment', key: 'sentiment', width: 22 },
        { header: 'Bericht', key: 'message', width: 32 },
      ]

      const chargingPoints = this.comments
        // Filter comments based on chargingPointUuid to eliminate undefined's //
        .filter((comment) => {
          const chargingPoint = this.getChargingPointByUuid({
            uuid: comment.data.chargingpointUuid,
          })
          // Because of inconsistent data, prop addres is sometimes false on not there at all //
          return chargingPoint?.data?.address
        })
        .map((comment) => {
          const chargingpoint = this.getChargingPointByUuid({
            uuid: comment.data.chargingpointUuid,
          })
          // Streetnumber is returned as a string or a number: convert to string for better sheet alignment //
          const streetnumber =
            chargingpoint.data.address.number?.toString() || ''
          return {
            ...comment.data,
            charginpoint_id: `${this.getActiveMunicipality}-${chargingpoint.data.properties.id}`,
            street: chargingpoint.data.address.street,
            streetnumber,
            streetsuffix: chargingpoint.data.address.suffix,
            postalcode: chargingpoint.data.address.postalcode,
            sentiment: this.formatSentiment(comment.data.sentiment),
            created_at: new Date(comment.data.created_at),
            updated_at: new Date(comment.data.updated_at),
          }
        })

      worksheet.addRows(chargingPoints)
      this.setWorksheetStyle(worksheet)

      const buffer = await workbook.xlsx.writeBuffer({ base64: true })
      const type =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      const title = `${
        this.municipalityLabel
      }-participatie-${getDateStamp()}.xlsx`
      saveAs(new Blob([buffer], { type }), title)
    },
    filteredComments({ data }) {
      return data.code === this.getActiveMunicipality && this.isDateWithinRange({ date: data.created_at })
    },
    isDateWithinRange ({ date }) {
      if (!this.hasSelectedPeriod) return true
      const endDate = this.endDateValue || this.participationConfig.closeDate
      return date <= Date.parse(endDate) && date > Date.parse(this.startDateValue)
    },
  },
}
</script>

<style lang="scss">
.Pages {
  position: relative;
  overflow-y: scroll;
}
.datepicker {
  min-width: 230px;
}
.PrintBtn {
  &.excel {
    &.disabled {
      color: inherit !important;
      border-color: inherit !important;
    }
  }
  &.all-reactions {
    margin-right: 4.3em;
  }
  gap: 1em;
}

.Factsheet {
  table {
    width: 100%;

    td {
      padding: 6px 0;
    }
  }

  &__Text {
    padding: 12px;
    width: 300px;
  }

  &__Donutchart {
    display: flex;
    align-items: center;

    &__Color {
      width: 15px;
      height: 15px;
    }
  }

  .Circle {
    text-align: center;

    .Circle {
      background: var(--secondary);
      width: 60px;
      height: 60px;
      border-radius: 50%;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      margin: 0 auto 3px;
    }
  }
}
</style>
