import { checkStatus, returnJson } from '@/helpers/api'
import { ATTACHMENT_ID, validAttachmentIDs } from '@/../shared/valueholders/attachments'
import { Bugfender } from '@bugfender/sdk'
import { mapGetters } from 'vuex'
import { isNumeric } from '@/helpers/string'

export default {
  data() {
    return {
      loading: {
        down: false,
        up: false,
        remove: false,
      },
      attachments: {
        [ATTACHMENT_ID.TOP]: {
          id: ATTACHMENT_ID.TOP,
          file: null,
          filename: null,
          type: null,
          url: null,
          removed: false,
          changed: false,
          edited: false,
        },
        [ATTACHMENT_ID.SIDE]: {
          id: ATTACHMENT_ID.SIDE,
          file: null,
          filename: null,
          type: null,
          url: null,
          removed: false,
          changed: false,
          edited: false,
        },
      },
      bulkUploadItems: [],
      bulkUploadFails: [],
    }
  },
  computed: {
    ...mapGetters('planmode', [
      'getChargingPoints',
    ]),
    isLoadingAws() {
      return this.loading.down || this.loading.up || this.loading.remove
    },
  },
  methods: {
    /**
     * PUBLIC METHODS
     */
    $_chargingpointAttachmentsMixin_handleDrop(event, id) {
      this.isDragging = false

      if (! event.dataTransfer.files.length > 0) {
        return
      }

      if (id === ATTACHMENT_ID.BULK) {
        this.processMultipleFiles(event.dataTransfer.files)
        return
      }

      this.processFiles(event.dataTransfer.files, id)
    },

    async $_chargingpointAttachmentsMixin_handleFileChange(event, id) {
      if (! event.target.files.length > 0) {
        return
      }

      if (id === ATTACHMENT_ID.BULK) {
        this.processMultipleFiles(event.target.files)
        return
      }

      await this.processFiles(event.target.files, id)
    },

    $_chargingpointAttachmentsMixin_handleFileRemove(id) {
      if (! validAttachmentIDs.includes(id)) {
        return
      }

      this.resetAttachment({ id })

      this.attachments[id].removed = true
    },

    async $_chargingpointAttachmentsMixin_cleanFetchAttachments() {
      Object.values(this.attachments)
        .forEach(attachment => this.resetAttachment({ id: attachment.id }))

      await this.fetchAttachments()
    },

    $_chargingpointAttachmentsMixin_bulkUploadAttachments() {
      this.loading.up = true

      this.bulkUploadItems.forEach(item => item.uploaded = false)

      Promise.all(
        this.bulkUploadItems.map(async item => {
          return this.uploadAttachment({
            file: item.file,
            data: {
              code: item.code,
              id: item.id,
              uuid: item.uuid,
              view: item.view,
              edited: item.edited,
              type: item.file.type,
              size: item.file.size,
            },
          })
        }),
      )
        .then(() => this.$notify({
          type: 'success',
          title: 'Upload is klaar!',
        }))
        .finally(() => this.loading.up = false)
    },

    async $_chargingpointAttachmentsMixin_uploadAttachment({ attachment }) {
      this.loading.up = true

      const id = attachment.id

      if (! validAttachmentIDs.includes(id)) {
        return
      }

      if (! this.attachments[id].file) {
        return
      }

      await this.uploadAttachment({
        file: this.attachments[id].file,
        data: {
          code: this.getActiveMunicipality,
          id: this.chargingpoint.data.properties.id,
          uuid: this.chargingpoint.data.uuid,
          view: id,
          edited: this.attachments[id].edited,
          type: this.attachments[id].file.type,
          size: this.attachments[id].file.size,
        },
      })

      this.attachments[id].file = null
      this.attachments[id].removed = false

      this.loading.up = false
    },

    $_chargingpointAttachmentsMixin_deleteAttachments() {
      Object.values(this.attachments).forEach(async attachment => {
        if (attachment.removed) {
          await this.removeAttachment({ id: attachment.id })
        }
      })
    },

    $_chargingpointAttachmentsMixin_handleRemoveItemFromBulk(item) {
      const index = this.bulkUploadItems.find(x => x.uuid === item.uuid && x.view === item.view)
      this.bulkUploadItems.splice(index, 1)
    },

    /**
     * HELPER
     */
    resetAttachment({ id }) {
      // RESET THUMBNAIL
      this.attachments[id].file = null
      this.attachments[id].filename = null
      this.attachments[id].type = null
      this.attachments[id].url = null
      this.attachments[id].changed = false
      this.attachments[id].edited = false
      this.attachments[id].removed = false
    },

    processMultipleFiles(files) {
      files.forEach(file => {
        const editedTerms = [
          'edited',
          'ingetekend',
        ]

        const topViewTerms = [
          'bovenaanzicht',
          'top',
        ]
        const sideViewTerms = [
          'zijaanzicht',
          'side',
        ]

        const edited = editedTerms.some(term => file.name.search(term) !== -1)
        const isSideView = sideViewTerms.some(term => file.name.search(term) !== -1)
        const isTopView = topViewTerms.some(term => file.name.search(term) !== -1)

        let view = false
        if (isSideView) {
          view = ATTACHMENT_ID.SIDE
        } else if (isTopView) {
          view = ATTACHMENT_ID.TOP
        }

        const regexSplitName = /(\d{4})(-|_)(\d*)/
        const matches = file.name.match(regexSplitName)

        if (matches === null) {
          Bugfender.warn(`process file attachment: can't match name: ${file.name}`)
          return
        }

        const code = matches[1]
        const id = parseInt(matches[3])

        // validate
        if (
          ! isNumeric(code) ||
          ! isNumeric(id) || typeof id !== 'number'
        ) {
          Bugfender.warn(`process file attachment: invalid code or id for file: ${file.name}`)
          return
        }

        if (id === false) {
          Bugfender.warn(`process file attachment: invalid attachment view for file: ${file.name}`)
          return
        }

        const chargingpoint = this.getChargingPoints.find(chargingpoint => {
          return chargingpoint.data.code === code && chargingpoint.data.properties.id === id
        })

        if (! chargingpoint) {
          Bugfender.warn(`process file attachment: can't find chargingpoint for file: ${file.name}`)
          return
        }

        const uuid = chargingpoint.data.uuid

        this.bulkUploadItems.push({
          code,
          id,
          uuid,
          view,
          file,
          edited,
          url: URL.createObjectURL(file),
          uploaded: false,
        })
      })
    },

    async processFiles(files, id) {
      if (! validAttachmentIDs.includes(id)) {
        return
      }

      const file = files[0]

      this.attachments[id].file = file
      this.attachments[id].url = URL.createObjectURL(file)

      await this.$_chargingpointAttachmentsMixin_uploadAttachment({ attachment: this.attachments[id] })
    },

    /*
    * S3 API
    */
    async fetchAttachments() {
      this.loading.down = true

      const token = await this.$auth.getTokenSilently()

      for (const id of validAttachmentIDs) {
        try {
          const response = await fetch(`${process.env.VUE_APP_AWS_BASE_URL}/download`, {
            method: 'POST',
            headers: {
              authorization: 'Bearer ' + token,
            },
            body: JSON.stringify({
              code: this.getActiveMunicipality,
              id: this.chargingpoint.data.properties.id,
              uuid: this.chargingpoint.data.uuid,
              view: id,
            }),
          })
            .then(await checkStatus)
            .then(returnJson)

          if (! response.downloadURL) {
            continue
          }

          this.attachments[id].filename = response.filename
          this.attachments[id].type = response.filetype

          fetch(response.downloadURL)
            .then(await checkStatus)
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => this.attachments[id].url = url)

        } catch (e) {
          if (e.message === 'File not found') {
            continue
          }

          // eslint-disable-next-line no-console
          console.log('error in file download')
        }
      }

      this.loading.down = false
    },

    async uploadAttachment({ file, data }) {
      // todo:: add bool for this location has an asset

      const token = await this.$auth.getTokenSilently()

      try {
        let response = await fetch(`${process.env.VUE_APP_AWS_BASE_URL}/upload`, {
          method: 'POST',
          headers: {
            authorization: 'Bearer ' + token,
          },
          body: JSON.stringify(data),
        })
          .then(await checkStatus)
          .then(returnJson)

        if (! response.uploadURL) {
          return
        }

        const buffer = await file.arrayBuffer()

        return fetch(response.uploadURL, {
          method: 'PUT',
          body: buffer,
        })
          .then(await checkStatus)
          .then(() => {
            const index = this.bulkUploadItems.findIndex(item => item.file.name === file.name)
            if (index !== -1) {
              this.bulkUploadItems[index].uploaded = true
            }
          })

      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error in file upload', e)
        this.bulkUploadFails.push({ message: 'cant upload', error: e, file, data })

        const index = this.bulkUploadItems.findIndex(item => item.uuid === data.uuid && item.view === data.view)
        if (index !== -1) {
          this.bulkUploadItems[index].error = true
        }
      }
    },

    async removeAttachment({ id }) {
      this.loading.remove = true

      if (! validAttachmentIDs.includes(id)) {
        return
      }

      const token = await this.$auth.getTokenSilently()

      try {
        await fetch(`${process.env.VUE_APP_AWS_BASE_URL}/delete`, {
          method: 'POST',
          headers: {
            authorization: 'Bearer ' + token,
          },
          body: JSON.stringify({
            code: this.getActiveMunicipality,
            id: this.chargingpoint.data.properties.id,
            uuid: this.chargingpoint.data.uuid,
            view: id,
            type: this.attachments[id].type,
          }),
        })
          .then(await checkStatus)

      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error removing image')
      }

      this.loading.remove = false
    },
  },
}
